const bankNames = [
  {
    name: 'Select item...',
    value: ''
  },
  {
    name: 'Access Bank',
    value: 'Access Bank'
  },
  {
    name: 'Access Bank (Diamond)',
    value: 'Access Bank (Diamond)'
  },
  {
    name: 'ALAT by WEMA',
    value: 'ALAT by WEMA'
  },
  {
    name: 'ASO Savings and Loans',
    value: 'ASO Savings and Loans'
  },
  {
    name: 'Bowen Microfinance Bank',
    value: 'Bowen Microfinance Bank'
  },
  {
    name: 'CEMCS Microfinance Bank',
    value: 'CEMCS Microfinance Bank'
  },
  {
    name: 'Citibank Nigeria',
    value: 'Citibank Nigeria'
  },
  {
    name: 'Ecobank Nigeria',
    value: 'Ecobank Nigeria'
  },
  {
    name: 'Ekondo Microfinance Bank',
    value: 'Ekondo Microfinance Bank'
  },
  {
    name: 'Fidelity Bank',
    value: 'Fidelity Bank'
  },
  {
    name: 'First Bank of Nigeria',
    value: 'First Bank of Nigeria'
  },
  {
    name: 'First City Monument Bank',
    value: 'First City Monument Bank'
  },
  {
    name: 'Globus Bank',
    value: 'Globus Bank'
  },
  {
    name: 'Guaranty Trust Bank',
    value: 'Guaranty Trust Bank'
  },
  {
    name: 'Hasal Microfinance Bank',
    value: 'Hasal Microfinance Bank'
  },
  {
    name: 'Heritage Bank',
    value: 'Heritage Bank'
  },
  {
    name: 'Jaiz Bank',
    value: 'Jaiz Bank'
  },
  {
    name: 'Keystone Bank',
    value: 'Keystone Bank'
  },
  {
    name: 'Kuda Bank',
    value: 'Kuda Bank'
  },
  {
    name: 'One Finance',
    value: 'One Finance'
  },
  {
    name: 'Parallex Bank',
    value: 'Parallex Bank'
  },
  {
    name: 'Polaris Bank',
    value: 'Polaris Bank'
  },
  {
    name: 'Providus Bank',
    value: 'Providus Bank'
  },
  {
    name: 'Rubies MFB',
    value: 'Rubies MFB'
  },
  {
    name: 'Sparkle Microfinance Bank',
    value: 'Sparkle Microfinance Bank'
  },
  {
    name: 'Stanbic IBTC Bank',
    value: 'Stanbic IBTC Bank'
  },
  {
    name: 'Standard Chartered Bank',
    value: 'Standard Chartered Bank'
  },
  {
    name: 'Sterling Bank',
    value: 'Sterling Bank'
  },
  {
    name: 'Suntrust Bank',
    value: 'Suntrust Bank'
  },
  {
    name: 'TAJ Bank',
    value: 'TAJ Bank'
  },
  {
    name: 'TCF MFB',
    value: 'TCF MFB'
  },
  {
    name: 'Titan Trust Bank',
    value: 'Titan Trust Bank'
  },
  {
    name: 'Union Bank of Nigeria',
    value: 'Union Bank of Nigeria'
  },
  {
    name: 'United Bank For Africa',
    value: 'United Bank For Africa'
  },
  {
    name: 'Unity Bank',
    value: 'Unity Bank'
  },
  {
    name: 'VFD',
    value: 'VFD'
  },
  {
    name: 'Wema Bank',
    value: 'Wema Bank'
  },
  {
    name: 'Zenith Bank',
    value: 'Zenith Bank'
  }
];

export default bankNames;

import React from 'react';
import { Switch, Route } from 'react-router';
import { pure } from 'recompose';

import ProtectedRoute from './ProtectedRoute';

import Dashboard from '../components/pages/Dashboard';
import MdaSetup from '../components/pages/MdaSetup';
import RolesSetup from '../components/pages/RolesSetup';
import EmployeeProfile from '../components/pages/EmployeeProfile';
import EmployeeDetails from '../components/pages/EmployeeDetails';
import SupervisorOrganogram from '../components/pages/SupervisorOrganogram';
import SessionHOC from '../components/hoc/SessionHOC';
import Module from '../components/pages/Module';
import ModuleCategory from '../components/pages/ModuleCategory';
import AccessSetup from '../components/pages/AccessSetup';
import ModulePart from '../components/pages/ModulePart';
import ModulePartRole from '../components/pages/ModulePartRole';

export default pure(() => (
  <Switch>
    <ProtectedRoute path="/admin-portal/" exact component={SessionHOC(Dashboard)} />
    <ProtectedRoute path="/admin-portal/mda-setup" exact component={SessionHOC(MdaSetup)} />
    <ProtectedRoute path="/admin-portal/roles-setup" exact component={SessionHOC(RolesSetup)} />
    <ProtectedRoute
      path="/admin-portal/employee-profile"
      exact
      component={SessionHOC(EmployeeProfile)}
    />
    <ProtectedRoute
      path="/admin-portal/access-setup"
      exact
      component={SessionHOC(AccessSetup)}
    /> 
    <ProtectedRoute
      path="/admin-portal/employee-details"
      exact
      component={SessionHOC(EmployeeDetails)}
    />
    <ProtectedRoute
      path="/admin-portal/supervisor-organogram"
      exact
      component={SessionHOC(SupervisorOrganogram)}
    />
    <ProtectedRoute path="/admin-portal/module" exact component={SessionHOC(Module)} />
    <ProtectedRoute
      path="/admin-portal/module-category"
      exact
      component={SessionHOC(ModuleCategory)}
    />
    <ProtectedRoute path="/admin-portal/module-part" exact component={SessionHOC(ModulePart)} />
    <ProtectedRoute
      path="/admin-portal/module-part-role"
      exact
      component={SessionHOC(ModulePartRole)}
    />
    <Route render={() => <h1>Error 404. Page not found.</h1>} />
  </Switch>
));

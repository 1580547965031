/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { RiAddLine } from 'react-icons/ri';
import { BiEditAlt } from 'react-icons/bi';
import { GiTrashCan } from 'react-icons/gi';

import SearchInput from '../inputs/SearchInput';
import LocationDetails from '../layouts/LocationDetails';
import MdaForm from '../layouts/MdaForm';
import { del, get } from '../../api-services/fetch';
import IsLoading from '../common/IsLoading';
import notification from '../../utility/notification';
import { formatDateTime } from '../../utility/dateTime';
import Pagination from  "react-paginate";
import ReactPaginate from 'react-paginate';
import TablePaginationActions from '../common/TablePaginationActions';
import Greeting from '../common/Greeting';


const MdaSetup = ({ history }) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [details, setDetails] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [searchText, setSearchText] = useState('');


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - MDA Setup';

    getMdaList({});
  }, []);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getMdaList = async ({ query = null }) => {
    console.log(query);
    setIsLoading(true);
    setErrorMsg('');
    const res = await get('mda', { query }, null, true);

    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        setDetails(data.data);
        if (data.data.length < 1) {
          if (query) {
            setErrorMsg('MDA not found');
          } else {
            setErrorMsg('No MDA yet');
          }
        }
      } else {
        setErrorMsg(data.message);
      }
    } else {
      setErrorMsg('Something has gone wrong. Please, try again.');
    }
    setIsLoading(false);
  };
 

  const fetchMdaList = async ({ query = searchText }) => {
    console.log(query);
    setIsLoading(true);
    setErrorMsg('');
    const res = await get('mda', { query }, null, true);

    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        setDetails(data.data);
        if (data.data.length < 1) {
          if (query) {
            setErrorMsg('MDA not found');
          } else {
            setErrorMsg('No MDA yet');
          }
        }
      } else {
        setErrorMsg(data.message);
      }
    } else {
      setErrorMsg('Something has gone wrong. Please, try again.');
    }
    setIsLoading(false);
  };

  const showEditForm = (id) => {
    const selected = details.filter((item) => id === item.id);

    if (selected.length > 0) {
      setSelectedRecord(selected[0]);
      setShowFormModal(true);
    }
  };

  const closeModal = () => {
    setSelectedRecord([]);
    setShowFormModal(false);
  };

  const deleteItem = async (id, name) => {
    const confirmAction = confirm(`Click OK to confirm the deletion of "${name}"`);
    if (!confirmAction) return;
    setIsDeleting(true);
    const res = await del('mda', id, true);

    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        notification({
          title: 'Successful MDA Deletion',
          message: `You have successfully deleted an MDA with name:${name}.`,
          type: 'success'
        });
        const others = details.filter((item) => id !== item.id);
        setDetails(others);
      } else {
        notification({
          title: 'MDA Deletion Error',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Network Error',
        message: 'Something went wrong while deleting an MDA. Please, try again later.',
        type: 'danger'
      });
    }
    setIsDeleting(false);
  };

  const handleSearch = async (e) => {
    const { name, value } = e.target;

    if (name === 'searchText') {
      setSearchText(value);
      console.log(value);
      await getMdaList({ query: value });
    }
  };

  const updateList = (newItem, isUpdate) => {
    if (isUpdate) {
      const newList = details.map((item) => (item.id === newItem.id ? newItem : item));
      setDetails(newList);
    } else {
      const newList = [...details];
      newList.unshift(newItem);
      setDetails(newList);
    }
  };

  return (
    <div className="w-100">
      <div className="w-100 flex space-between">
      <Greeting />
        <LocationDetails />
      </div>
      <div className="w-100 flex space-between flex-v-center m-t-20">
        <h2>Create MDA</h2>
        <SearchInput
          name="searchText"
          value={searchText}
          onChange={handleSearch}
          placeholder="Search list..."
        />
      </div>
      <div className="table-view m-t-10">
        <div className="table-header">
          <div className="table-row">
            <div className="th"><p>MDA List</p></div>
            <div className="th"><p>Permanant Sec.</p></div>
            <div className="th"><p>Last Updated</p></div>
            <div className="th"><p>Last Updated By</p></div>
            <div className="th">
              <button className="add-button pointer" type="button" onClick={() => setShowFormModal(true)}>
                <RiAddLine />
                Add MDA
              </button>
            </div>
          </div>
        </div>
        <div className="table-body">
          {!(isLoading || errorMsg) && details && (rowsPerPage > 0
                ? details.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : details
              ).map((item) => (
            <div className="table-row" key={item.id}>
              <div className="td">{item.name}</div>
              <div className="td">{item.permanentSecretaryId}</div>
              <div className="td">{formatDateTime(item.updatedAt || item.createdAt)}</div>
              <div className="td">{item.updatedBy || item.createdBy}</div>
              <div className="td actions">
                <button type="button" className="pointer" onClick={() => showEditForm(item.id)}>
                  <BiEditAlt />
                </button>
                <button
                  type="button"
                  className="m-l-10 del pointer"
                  onClick={() => deleteItem(item.id, item.name)}
                >
                  <GiTrashCan />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {isLoading && <div className="w-100 center-text p-20">Loading...</div>}
      {errorMsg && <div className="w-100 center-text p-20">{errorMsg}</div>}
      {(isLoading || isDeleting) && <IsLoading message={isDeleting ? 'Deleting...' : null} />}
      {showFormModal && (
        <MdaForm details={selectedRecord} updateList={updateList} closeModal={closeModal} />
      )}
      <TablePaginationActions
            page={page}
            rowsPerPage={rowsPerPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            count={details.length}
            changeRowsPerPage={setRowsPerPage}
          />
    </div>
  );
};

export default MdaSetup;

import React, { useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';

import Loader from '../../assets/svg/loading.svg';
import TextareaInput from '../inputs/TextareaInput';
import TextInput from '../inputs/TextInput';
// import FormSearchInput from '../inputs/FormSearchInput';
import { toggleScroll } from '../../utility/general';
import notification from '../../utility/notification';
import { patch, post } from '../../api-services/fetch';

const RoleForm = ({ closeModal, updateList, details }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  // const [mdas, setMdas] = useState();

  const [formType, setFormType] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    toggleScroll();
    if (details && details.id) {
      setFormType('edit');
      setName(details.name);
      setDescription(details.description);
    } else {
      setFormType('create');
    }

    return () => setTimeout(() => {
      toggleScroll();
    }, 0);
  }, [details]);

  const submitForm = async () => {
    const body = {
      name,
      description
    };
    const error = validateForm(body);

    if (error) {
      notification({
        title: 'Invalid Entry',
        message: error,
        type: 'danger'
      });
      return;
    }

    setIsLoading(true);
    const res = formType === 'create'
      ? await post('role', body, true) : await patch('role', body, details.id, true);
    console.log(res);
    setIsLoading(false);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        notification({
          title: `Successful Role ${formType === 'create' ? 'Creation' : 'Update'}`,
          message: data.message,
          type: 'success'
        });
        updateList(data.data, formType === 'edit');
        if (formType === 'edit') {
          closeModal();
          return;
        }
        setName('');
        setDescription('');
      } else {
        notification({
          title: `Role ${formType === 'create' ? 'Creation' : 'Update'} Error`,
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Network Error',
        message: `Something went wrong while ${formType === 'create' ? 'creating' : 'updating'} an Role. Please, try again later.`,
        type: 'danger'
      });
    }
  };

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box max-w-400">
        <div className="content">
          <TextInput
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-100 m-b-10"
            label="Role’s Name*"
          />
          <TextareaInput
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-100 m-b-10"
            rows={4}
            label="Description*"
          />
          {/* <FormSearchInput
            name="mdas"
            value={mdas}
            onChange={(e) => setMdas(e.target.value)}
            className="w-100 m-b-10"
            label="Select MDA"
          />
          <label className="m-b-10 flex flex-v-center">
            <input type="checkbox" />
            Apply to all MDAs
          </label> */}
          <button
            type="button"
            className="btn btn-large w-100"
            onClick={submitForm}
            disabled={isLoading}
          >
            {!isLoading && `${formType === 'create' ? 'Create' : 'Update'} Role`}
            {isLoading && `${formType === 'create' ? 'Creating' : 'Updating'} Role...`}
            {isLoading && <img src={Loader} className="btn-loading" alt="Loading..." />}
          </button>
        </div>
      </div>
    </div>
  );
};

const validateForm = ({
  name, description
}) => {
  if (!name) return 'Please, enter a name for the Role';
  if (!description) return 'Please, enter a description for the Role';
  return null;
};

export default RoleForm;

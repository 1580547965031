/* eslint-disable implicit-arrow-linebreak */
import axios from 'axios';
import paths from './endpoints';

import { decodeToken, logout } from '../utility/auth';

const getToken = () => {
  const t = decodeToken('t');
  const token = t && t.t;
  return token;
};

const fetchBackend = async (endpoint, method, auth, body, pQuery, param) => {
  const headers = {
    'X-API-KEY': process.env.REACT_APP_API_KEY,
    'Content-Type': 'application/json'
  };
  const path = paths[endpoint] || endpoint;
  let url = `${process.env.REACT_APP_BACKEND_URL}${path}`;

  console.log('Logging url', url);

  if (param) {
    url += `/${param}`;
  }

  if (pQuery) {
    console.log('logging pquery', pQuery);
    const paramsArray = Object.keys(pQuery).map(
      (key) => pQuery[key] && `${encodeURIComponent(key)}=${encodeURIComponent(pQuery[key])}`
    );

    url += `?${paramsArray.join('&')}`;
  }

  if (auth) {
    const token = getToken();
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
  }
  const options = {
    url,
    method,
    headers
  };

  if (body) {
    options.data = body;
  }

  console.log(options);
  return axios(options).then(
    (res) => res,
    async (err) => {
      if (err && err.response && err.response.status === 401) {
        // log the user out and return
        console.log('UNAUTHORIZED REQUEST...');
        await logout('/', true);
      }
      console.log(err.response);
      return err.response;
    }
  );
};

/**
 *
 * @param {string} endpoint
 * @param {object} pQuery
 * @param {string} param
 * @param {boolean} auth
 */
export const get = (endpoint, pQuery, param = null, auth = true) =>
  fetchBackend(endpoint, 'GET', auth, null, pQuery, param);

/**
 *
 * @param {string} endpoint
 * @param {object} body
 * @param {boolean} auth
 */
export const post = (endpoint, body, auth = true) => fetchBackend(endpoint, 'POST', auth, body);

/**
 *
 * @param {string} endpoint
 * @param {object} body
 * @param {string} param
 * @param {boolean} auth
 */
export const patch = (endpoint, body, param, auth = true) =>
  fetchBackend(endpoint, 'PATCH', auth, body, null, param);

/**
 *
 * @param {string} endpoint
 * @param {object} body
 * @param {string} param
 * @param {boolean} auth
 */
export const put = (endpoint, body, param, auth = true) =>
  fetchBackend(endpoint, 'PUT', auth, body, null, param);

/**
 *
 * @param {string} endpoint
 * @param {string} param
 * @param {boolean} auth
 */
export const del = (endpoint, param, auth = true) =>
  fetchBackend(endpoint, 'DELETE', auth, null, null, param);

const paths = {
  login: 'global/api/employee/login',
  mda: 'global/api/mda',
  postAccess: 'global/api/createAccess',
  access: 'global/api/getAccessTypes',
  accessUsers: 'global/api/GetUserAccess',
  role: 'global/api/role',
  user:'global/api/user',
  employeeProfile: 'global/api/employee/profile',
  employeeDetails: 'global/api/employee/details',
  organogram: 'global/api/organogram',
  moduleCategory: 'rolemodule/api/ModuleCategory',
  module: 'rolemodule/api/UrlLinks',
  modulePart: 'rolemodule/api/ModuleParts',
  modulePartRole: 'rolemodule/api/ModulePartRoles',
  moduleRoles: 'rolemodule/api/Roles',
  education: 'global/api/education',
  professional: 'global/api/profcerts',
  'next-of-kin': 'global/api/nok',
  dependant: 'global/api/dependants'
};

export default paths;


import React, { useState, useEffect } from 'react';
import { IoMdClose } from 'react-icons/io';
import { get, patch, post } from '../../api-services/fetch';

// import { patch, post } from '../../api-services/fetch';
import { toggleScroll } from '../../utility/general';
import Loader from '../../assets/svg/loading.svg';
import AutoComplete from '../inputs/AutoComplete';

import TextareaInput from '../inputs/TextareaInput';
import TextInput from '../inputs/TextInput';
import notification from '../../utility/notification';
import { formatDateforPicker, getDaysFromDate } from '../../utility/dateTime';

const MdaForm = ({ details, updateList, closeModal }) => {
  const [name, setName] = useState('');
  const [mandate, setMandate] = useState('');
  const [dateOfEstablishment, setDateOfEstablishment] = useState('');
  const [address, setAddress] = useState('');
  const [formType, setFormType] = useState('');
  const [permanentSecretaryId, setpermanentSecretaryId] = useState();
  const [permanentSecretary, setpermanentSecretary] = useState(0);
  const [employeeList, setEmployeeList] = useState([]);
  const [userId, setUserId] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    

    getEmployeeList()

    toggleScroll();
    if (details && details.id) {
      console.log(details)
      setFormType('edit');
      setName(details.name || '');
      setMandate(details.mandate || details.purposeOfEstablishment || '');

      setDateOfEstablishment(details.dateOfEstablishment || '');
      setpermanentSecretaryId(details.permanentSecretary || '');
      setAddress(details.address || '');
      setpermanentSecretary(details?.permanentSecretary || 0)
    } else {
      setFormType('create');
    }

    return () => setTimeout(() => {
      toggleScroll();
    }, 0);
  }, [details]);


  const getEmployeeList = async () => {
    const res = await get('employeeProfile', null, null, true);
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        const roleList = data.data
          .map((item) => ({ name: `${item.firstName} ${item.lastName}`, value: item.userId }));
        console.log(roleList);
        setEmployeeList(roleList);
      } else {
        notification({
          title: 'Error getting List of Employees',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Error getting List of Employees',
        message: 'Something has gone wrong. Please, try again.',
        type: 'danger'
      });
    }
  };

  const fetchEmployeeList = async (searchText) => {
    const res = await get('employeeProfile', {query:searchText}, null, true);
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        const roleList = data.data
          .map((item) => ({ name: `${item.firstName} ${item.lastName}`, value: item.userId }));
        console.log(roleList);
        setEmployeeList(roleList);
      } else {
        notification({
          title: 'Error getting List of Employees',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Error getting List of Employees',
        message: 'Something has gone wrong. Please, try again.',
        type: 'danger'
      });
    }
  };


  const submitForm = async () => {
    const body = {
      name,
      address,
      dateOfEstablishment,
      permanentSecretaryId,
      mandate
    };
    const error = validateForm(body);

    if (error) {
      notification({
        title: 'Invalid Entry',
        message: error,
        type: 'danger'
      });
      return;
    }

    setIsLoading(true);
    const res = formType === 'create'
      ? await post('mda', body, true) : await patch('mda', body, details.id, true);
    console.log(res);
    setIsLoading(false);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        notification({
          title: `Successful MDA ${formType === 'create' ? 'Creation' : 'Update'}`,
          message: data.message,
          type: 'success'
        });
        updateList(data.data, formType === 'edit');
        if (formType === 'edit') {
          closeModal();
          return;
        }
        setName('');
        setMandate('');
        setAddress('');
        setpermanentSecretaryId('');
        setDateOfEstablishment('');
      } else {
        notification({
          title: `MDA ${formType === 'create' ? 'Creation' : 'Update'} Error`,
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Network Error',
        message: `Something went wrong while ${formType === 'create' ? 'creating' : 'updating'} an MDA. Please, try again later.`,
        type: 'danger'
      });
    }
  };

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box max-w-400">
        <div className="content">
          <TextInput
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-100 m-b-10"
            label="MDA Name*"
          />
          <TextareaInput
            name="mandate"
            value={mandate}
            onChange={(e) => setMandate(e.target.value)}
            className="w-100 m-b-10"
            rows={3}
            label="MDA’s Mandate*"
          />
          <TextInput
            name="dateOfEstablishment"
            value={dateOfEstablishment}
            type="date"
            onChange={(e) => setDateOfEstablishment(e.target.value)}
            maxDate={formatDateforPicker(new Date())}
            className="w-100 m-b-10"
            label="Date of Establishment*"
          />

          <AutoComplete
            label="Select Permanent Sec."
            // multiple
            options={employeeList || []}
            value={permanentSecretary}
            onChange={(value) => {
              console.log(value)
              setpermanentSecretaryId(value)
            }}
            getOptions={(e) => fetchEmployeeList(e)}
            // disabled={formType === 'edit'}
            className="w-100 m-b-10"
          />
          {/* <AutoComplete
            label="Assign Permanent Sec.*"
            multiple
            options={employeeList || []}
            value={permanentSecretaryId}
            onChange={(e) => setpermanentSecretaryId(e.target.value)}
            disabled={formType === 'edit'}
            className="w-100 m-b-10"
          /> */}
          {/* <AutoComplete
            name="permanentSecretaryId"
            className="w-100 m-b-10"
            options={employeeList || []}
          /> */}
          <TextInput
            name="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className="w-100 m-b-10"
            label="Address/Location*"
          />
          <button
            type="button"
            className="btn btn-large w-100"
            onClick={submitForm}
            disabled={isLoading}
          >
            {!isLoading && `${formType === 'create' ? 'Create' : 'Update'} MDA`}
            {isLoading && `${formType === 'create' ? 'Creating' : 'Updating'} MDA...`}
            {isLoading && <img src={Loader} className="btn-loading" alt="Loading..." />}
          </button>
        </div>
      </div>
    </div>
  );
};

const validateForm = ({
  name, address, dateOfEstablishment, mandate, permanentSecretaryId
}) => {
  if (!name) return 'Please, enter a name for the MDA';
  if (!mandate) return 'Please, enter a mandate for the MDA';
  if (!dateOfEstablishment) return 'Please, enter date of Establishment for the MDA';
  // if (!permanentSecretaryId) return 'Please, choose the permanent Secretary for the MDA';
  if (getDaysFromDate(dateOfEstablishment) > 0) return 'Date of Establishment for the MDA must not be beyond today';
  if (!address) return 'Please, enter an address for the MDA';
  return null;
};

export default MdaForm;

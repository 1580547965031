import { GiTrashCan } from "react-icons/gi";
import IsLoading from "../common/IsLoading";
import TablePaginationActions from "../common/TablePaginationActions";
import MdaForm from "../layouts/MdaForm";
import { BiEditAlt } from "react-icons/bi";
import { RiAddLine } from "react-icons/ri";
import SearchInput from "../inputs/SearchInput";
import LocationDetails from "../layouts/LocationDetails";
import Greeting from "../common/Greeting";
import { useEffect, useState } from "react";
import { formatDateTime } from "../../utility/dateTime";
import { get } from "../../api-services/fetch";
import SelectInput from "../inputs/SelectInput";
import AccessForm from "../layouts/AccessForm";

const AccesSetup = () => {

    const [showFormModal, setShowFormModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [details, setDetails] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');
    const [searchText, setSearchText] = useState('');
    const [AccessTypes, setAccessTypes] = useState([]);


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);



    const closeModal = () =>{
        setShowFormModal(false);
        
    }

    const getAccessList = async () => {

        setIsLoading(true);
        setErrorMsg('');
        const res = await get('access', null);

        console.log(res);

        if (res && res.status === 200) {
            const { data } = res;
            if (data.code === 1) {
                let accessList = data.data.map((data) => ({ name: data?.accessType, value: data.id }))
                accessList?.unshift({ name: "Select Access", value: 0 })
                setAccessTypes(accessList);
                console.log(data.data);
            }
        } else {
            setErrorMsg('Something has gone wrong. Please, try again.');
        }
        setIsLoading(false);
    };

    const handleAccessChange = async (e) => {
        const { value } = e.target;
        getAccessUsers(value);
    }


    const getAccessUsers = async (id) => {

        if (id !== 0 && id !== "0") {
            setIsLoading(true);
            setErrorMsg('');

            const res = await get('accessUsers', null, id);

            console.log(res);

            if (res && res.status === 200) {
                const { data } = res;
                if (data.code === 1) {
                    setDetails(data.data);
                    console.log(data.data);
                }
            } else {
                setErrorMsg('Something has gone wrong. Please, try again.');
            }
        }

        setIsLoading(false);
    };



    useEffect(() => {
        getAccessList()
    }, [])


    return (
        <div className="w-100">
            <div className="w-100 flex space-between">
                <Greeting />
                <LocationDetails />
            </div>
            <div className="w-100 flex space-between flex-v-center m-t-20">
                <h2>Select Access</h2>
                {/* <SearchInput
                            name="searchText"
                            value={searchText}
                            onChange={handleSearch}
                            placeholder="Search list..."
                        /> */}
            </div>
            <div className="flex space-between w-100">
                <div className="w-50">
                    <label htmlFor="accessTypes"></label>
                    <SelectInput onChange={(e) => handleAccessChange(e)} name="accessTypes" className="w-50" options={AccessTypes} />
                </div>

                <button className="add-button pointer" type="button" onClick={() => setShowFormModal(true)}>
                    <RiAddLine />
                    Set Access
                </button>
            </div>
            <div className="table-view m-t-10">

                <div className="table-header">

                    <div className="table-row">
                        <div className="th"><p>Name</p></div>
                        <div className="th"><p>Role</p></div>
                        <div className="th"><p>Access Status</p></div>
                        <div className="th"><p>Last Updated</p></div>
                        <div className="th"><p>Last Updated By</p></div>
                        {/* <div className="th">
                            Action
                             <button className="add-button pointer" type="button" onClick={() => setShowFormModal(true)}>
                                <RiAddLine />
                                Set Access
                            </button> 
                        </div> */}
                    </div>
                </div>
                <div className="table-body">
                    {!(isLoading || errorMsg) && details && (rowsPerPage > 0
                        ? details.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : details
                    ).map((item) => (
                        <div className="table-row" key={item.id}>
                            <div className="td">{item.name}</div>
                            <div className="td">{item.role}</div>
                            <div className="td">{item.accessStatus}</div>
                            <div className="td">{formatDateTime(item.updatedAt || item.createdAt)}</div>
                            <div className="td">{item.updatedBy || item.createdBy}</div>
                            {/* <div className="td actions">
                                onClick={() => showEditForm(item.id)}
                                <button type="button" className="pointer" >
                                    <BiEditAlt />
                                </button>
                                <button
                                    type="button"
                                    className="m-l-10 del pointer"
                                // onClick={() => deleteItem(item.id, item.name)}
                                >
                                    <GiTrashCan />
                                </button>
                            </div> */}
                        </div>
                    ))}
                </div>
            </div>
            {isLoading && <div className="w-100 center-text p-20">Loading...</div>}
            {errorMsg && <div className="w-100 center-text p-20">{errorMsg}</div>}
            {(isLoading || isDeleting) && <IsLoading message={isDeleting ? 'Deleting...' : null} />}
            {showFormModal && (
                <AccessForm
                    details={selectedRecord}
                    // updateList={updateList} 
                    closeModal={closeModal}
                />
            )}
            <TablePaginationActions
                page={page}
                rowsPerPage={rowsPerPage}
                // onChangePage={handleChangePage}
                // onChangeRowsPerPage={handleChangeRowsPerPage}
                count={details.length}
                changeRowsPerPage={setRowsPerPage}
            />
        </div>
    )
}

export default AccesSetup;
import React, { useEffect, useState } from 'react';

import { decodeToken } from '../../utility/auth';
import { getDayPeriod } from '../../utility/dateTime';

const Greeting = ({ details = {} }) => {
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');

  useEffect(() => {
    const r = localStorage.getItem('r');
    let fn = localStorage.getItem('fn');
    let ln = localStorage.getItem('ln');

    r && setRole(decodeToken('r').r);
    if (fn && ln) {
      fn = decodeToken('fn').fn;
      ln = decodeToken('ln').ln;
      setUsername(`${fn} ${ln}`);
    }
  }, []);

  return (
    <div className="greeting w-100">
      <p className="m-b-5">
        Good&nbsp;
        {getDayPeriod()}
      </p>
      <h3 className="m-b-5">{username || 'N/A'}</h3>
      <p className="" style={{ fontSize: '0.72rem' }}>
        {role || 'N/A'}
        ,
      </p>
    </div>
  );
};

export default Greeting;

const cadreSteps = [
  {
    name: 'Select item...',
    value: ''
  },
  {
    name: 'Step 1',
    value: 'Step 1'
  },
  {
    name: 'Step 2',
    value: 'Step 2'
  },
  {
    name: 'Step 3',
    value: 'Step 3'
  },
  {
    name: 'Step 4',
    value: 'Step 4'
  },
  {
    name: 'Step 5',
    value: 'Step 5'
  },
  {
    name: 'Step 6',
    value: 'Step 6'
  },
  {
    name: 'Step 7',
    value: 'Step 7'
  },
  {
    name: 'Step 8',
    value: 'Step 8'
  },
  {
    name: 'Step 9',
    value: 'Step 9'
  },
  {
    name: 'Step 10',
    value: 'Step 10'
  },
  {
    name: 'Step 11',
    value: 'Step 11'
  },
  {
    name: 'Step 12',
    value: 'Step 12'
  },
  {
    name: 'Step 13',
    value: 'Step 13'
  },
  {
    name: 'Step 14',
    value: 'Step 14'
  },
  {
    name: 'Step 15',
    value: 'Step 15'
  },
  {
    name: 'Step 16',
    value: 'Step 16'
  },
  {
    name: 'Step 17',
    value: 'Step 17'
  },
  {
    name: 'Consolidated',
    value: 'Consolidated'
  }
];

export default cadreSteps;

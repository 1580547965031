import React, { useState, useEffect, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { IoMdClose } from 'react-icons/io';
// import MultiSelectInput from '../inputs/MultiSelectInput';
import TextInput from '../inputs/TextInput';
import { get } from '../../api-services/fetch';
import notification from '../../utility/notification';
import { formatFileUrl } from '../../utility/general';
import ProfilePix from '../../assets/images/profile-pix.jpg';

const ViewProfile = ({ closeModal, staffId }) => {
  const [details, setStaffDetails] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [mda, setMda] = useState('');
  const [nationality, setNationality] = useState('');
  const [state, setState] = useState('');
  const [religion, setReligion] = useState('');
  const [education, setEducation] = useState([]);
  const [professional, setProfessional] = useState([]);
  const [nextofkin, setNextofKin] = useState([]);
  const [dependants, setDependants] = useState([]);
  const [profilePic, setProfilePic] = useState('');

  const componentRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Employee Profile';

    getStaffDetails();
    getEducation();
    getProfessional();
    getNextOfKin();
    getDependant();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStaffDetails = async () => {
    setIsLoading(true);
    setErrorMsg('');
    const res = await get('employeeProfile', null, staffId, true);
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        console.log(data.data);
        setMda(data.data.mda.name);
        setNationality(data.data.nationality.name);
        setState(data.data.state.name);
        setReligion(data.data.religion.name);
        setStaffDetails(data.data);
        setProfilePic(data.data.profilePicture);
        if (data.data.length < 1) {
          setErrorMsg('Employee Profile not found');
        }
      } else {
        setErrorMsg(data.message);
      }
    } else {
      setErrorMsg('Something has gone wrong. Please, try again.');
    }
    setIsLoading(false);
  };

  const getEducation = async () => {
    const res = await get('education', null, staffId, true);

    console.log(res);
    console.log('ok');
    if (res && res.status === 200) {
      const { data } = res;
      if (data) {
        const result = data.data;
        if (result) {
          console.log('logging education result', result);
          setEducation(result);
        }
      }
    } else {
      notification({
        title: 'Error getting List of Education',
        message: 'Something has gone wrong. Please, try again.',
        type: 'danger'
      });
    }
  };

  const getProfessional = async () => {
    const res = await get('professional', null, staffId, true);

    console.log(res);
    console.log('ok');
    if (res && res.status === 200) {
      const { data } = res;
      if (data) {
        const result = data.data;
        if (result) {
          console.log('logging professional Certificate result', result);
          setProfessional(result);
        }
      }
    } else {
      notification({
        title: 'Error getting List of professionals certification',
        message: 'Something has gone wrong. Please, try again.',
        type: 'danger'
      });
    }
  };

  const getNextOfKin = async () => {
    const res = await get('next-of-kin', null, staffId, true);

    console.log(res);
    console.log('ok');
    if (res && res.status === 200) {
      const { data } = res;
      if (data) {
        const result = data.data;
        if (result) {
          console.log('logging professional Certificate result', result);
          setNextofKin(result);
        }
      }
    } else {
      notification({
        title: 'Error getting List of Next OF Kin',
        message: 'Something has gone wrong. Please, try again.',
        type: 'danger'
      });
    }
  };

  const getDependant = async () => {
    const res = await get('dependant', null, staffId, true);

    console.log(res);
    console.log('ok');
    if (res && res.status === 200) {
      const { data } = res;
      if (data) {
        const result = data.data;
        if (result) {
          console.log('logging dependants result', result);
          setDependants(result);
        }
      }
    } else {
      notification({
        title: 'Error getting List of dependants',
        message: 'Something has gone wrong. Please, try again.',
        type: 'danger'
      });
    }
  };

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box max-w-700">
        <div className="content no-padding">
          <div ref={componentRef} className="p-20">
            <div className="w-100 m-t-10 center-text profile-brief">
              <img
                // src={pixUrl || formatFileUrl(profilePicture) || ProfilePix}
                src={formatFileUrl(profilePic) || ProfilePix}
                alt="Profile Pic."
              />
            </div>
            <TextInput name="staffId" value={staffId} className="w-100 m-b-10" label="Staff ID*" />
            <TextInput name="title" value={details.title} className="w-100 m-b-10" label="Title" />
            <TextInput
              name="firstName"
              value={details.firstName}
              className="w-100 m-b-10"
              label="First Name"
            />
            <TextInput
              name="lastName"
              value={details.lastName}
              className="w-100 m-b-10"
              label="Last Name"
            />
            <TextInput
              name="gender"
              value={details.gender}
              className="w-100 m-b-10"
              label="Gender"
            />
            <TextInput
              name="dob"
              value={details.dob}
              className="w-100 m-b-10"
              label="Date of Birth"
            />
            <TextInput
              name="maritalStatus"
              value={details.maritalStatus}
              className="w-100 m-b-10"
              label="Marital Status"
            />
            <TextInput
              name="nationality"
              value={nationality}
              className="w-100 m-b-10"
              label="Nationality"
            />
            <TextInput
              name="stateoforigin"
              value={state}
              className="w-100 m-b-10"
              label="State of Origin"
            />
            <TextInput
              name="lga"
              value={details.lga}
              className="w-100 m-b-10"
              label="Local Government of Origin"
            />
            <TextInput
              name="hometown"
              value={details.homeTown}
              className="w-100 m-b-10"
              label="Home Town"
            />
            <TextInput
              name="permHomeAddress"
              value={details.permHomeAddress}
              className="w-100 m-b-10"
              label="Permanent Home Address"
            />
            <TextInput
              name="residentialAddress"
              value={details.residentialAddress}
              className="w-100 m-b-10"
              label="Residential Home Address"
            />
            <TextInput
              name="languageSpoken"
              value={details.languageSpoken}
              className="w-100 m-b-10"
              label="Language Spoken"
            />
            <TextInput
              name="moblie"
              value={details.mobile}
              className="w-100 m-b-10"
              label="Mobile Number"
            />
            <TextInput
              name="personalEmail"
              value={details.email}
              className="w-100 m-b-10"
              label="Personal Email Address*"
            />
            <TextInput name="religion" value={religion} className="w-100 m-b-10" label="Religion" />
            <TextInput
              name="nin"
              value={details.nin}
              className="w-100 m-b-10"
              label="National Identity Number(NIN)"
            />
            <TextInput
              name="apppDate"
              value={details.dateOfFirstApp}
              className="w-100 m-b-10"
              label="Date of First Appointment into the service"
            />
            <TextInput
              name="fileNO"
              value={details.fileNumber}
              className="w-100 m-b-10"
              label="File Number"
            />
            <TextInput
              name="employeeNumber"
              value={details.employeeNumber}
              className="w-100 m-b-10"
              label="Employee Number"
            />
            <TextInput
              name="firstcarde"
              value={details.firstAppCadre}
              className="w-100 m-b-10"
              label="Cadre on First Appointment"
            />
            <TextInput
              name="gradeFirstApp"
              value={details.gradeOfFirstApp}
              className="w-100 m-b-10"
              label="Grade on First Appointment"
            />
            <TextInput
              name="stepFirstApp"
              value={details.stepOfFirstApp}
              className="w-100 m-b-10"
              label="Step on First Appointment"
            />
            <TextInput
              name="dateOfConfirmation"
              value={details.dateOfConfirmation}
              className="w-100 m-b-10"
              label="Date of Confirmation of Appointment"
            />
            <TextInput
              name="currentCadre"
              value={details.currentCadre}
              className="w-100 m-b-10"
              label="Current Cadre"
            />
            <TextInput
              name="currentLevel"
              value={details.currentLevel}
              className="w-100 m-b-10"
              label="Current Grade Level"
            />
            <TextInput
              name="currentStep"
              value={details.currentStep}
              className="w-100 m-b-10"
              label="Current Step"
            />
            <TextInput
              name="dateOfPresentPosition"
              value={details.dateOfPresentPosition}
              className="w-100 m-b-10"
              label="Date of Current Position"
            />
            <TextInput name="mda" value={mda} className="w-100 m-b-10" label="MDA" />
            <div className="table-view m-t-10">
              <div className="table-header">
                <div className="table-row">
                  <div className="th">
                    <p>School</p>
                  </div>
                  <div className="th">
                    <p>Course of Study</p>
                  </div>
                  <div className="th">
                    <p>Certificate</p>
                  </div>
                  <div className="th">
                    <p>Class of Degree</p>
                  </div>
                  <div className="th">
                    <p>Year</p>
                  </div>
                  <div className="th">
                    <p>Remark</p>
                  </div>
                </div>
              </div>
              <div className="table-body">
                {education.map((item) => (
                  <div className="table-row" key={item.id}>
                    <div className="td">{item.schoolName}</div>
                    <div className="td">{item.course}</div>
                    <div className="td">{item.certificate}</div>
                    <div className="td">{item.classOfDegree}</div>
                    <div className="td">{item.graduateYear}</div>
                    <div className="td">{item.remarks}</div>
                  </div>
                ))}
              </div>
            </div>

            <div className="table-view m-t-10">
              <div className="table-header">
                <div className="table-row">
                  <div className="th">
                    <p>Certificate Type</p>
                  </div>
                  <div className="th">
                    <p>Certificate Obtained</p>
                  </div>
                  <div className="th">
                    <p>Certificate Number</p>
                  </div>
                  <div className="th">
                    <p>Certificate Year</p>
                  </div>
                  <div className="th">
                    <p>Certificate Expiration Date</p>
                  </div>
                  <div className="th">
                    <p>Remark</p>
                  </div>
                </div>
              </div>
              <div className="table-body">
                {professional.map((item) => (
                  <div className="table-row" key={item.id}>
                    <div className="td">{item.CertType}</div>
                    <div className="td">{item.CertObtained}</div>
                    <div className="td">{item.CertNumber}</div>
                    <div className="td">{item.CertYear}</div>
                    <div className="td">{item.CertExpirationDate}</div>
                    <div className="td">{item.remarks}</div>
                  </div>
                ))}
              </div>
            </div>

            <div className="table-view m-t-10">
              <div className="table-header">
                <div className="table-row">
                  <div className="th">
                    <p>Next Of Kin Name</p>
                  </div>
                  <div className="th">
                    <p>Next Of Kin Address</p>
                  </div>
                  <div className="th">
                    <p>Next Of Kin Date Of Birth</p>
                  </div>
                  <div className="th">
                    <p>Next Of Kin Mobile</p>
                  </div>
                  <div className="th">
                    <p>Next Of Kin Relation</p>
                  </div>
                  <div className="th">
                    <p>Remark</p>
                  </div>
                </div>
              </div>
              <div className="table-body">
                {nextofkin.map((item) => (
                  <div className="table-row" key={item.id}>
                    <div className="td">{item.NOKName}</div>
                    <div className="td">{item.NOKAddress}</div>
                    <div className="td">{item.NOKDoB}</div>
                    <div className="td">{item.NOKMobile}</div>
                    <div className="td">{item.NOKRelation}</div>
                    <div className="td">{item.remarks}</div>
                  </div>
                ))}
              </div>
            </div>

            <div className="table-view m-t-10">
              <div className="table-header">
                <div className="table-row">
                  <div className="th">
                    <p>Dependant Name</p>
                  </div>
                  <div className="th">
                    <p>Dependant Address</p>
                  </div>
                  <div className="th">
                    <p>Dependant Date Of Birth</p>
                  </div>
                  <div className="th">
                    <p>Dependant Mobile</p>
                  </div>
                  <div className="th">
                    <p>Dependant Relation</p>
                  </div>
                  {/* <div className="th">
                  <p>Remark</p>
                </div> */}
                </div>
              </div>
              <div className="table-body">
                {dependants.map((item) => (
                  <div className="table-row" key={item.id}>
                    <div className="td">{item.DepName}</div>
                    <div className="td">{item.DepAddress}</div>
                    <div className="td">{item.DepDoB}</div>
                    <div className="td">{item.DepRelation}</div>
                    <div className="td">{item.DepMobile}</div>
                    {/* <div className="td">{item.remarks}</div> */}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <ReactToPrint
            trigger={() => (
              <button type="button" className="btn btn-large w-100">
                Print
              </button>
            )}
            content={() => componentRef.current}
          />

          {/* <button type="button" className="btn btn-large w-100" onClick={() => window.print()}>
            Print
          </button> */}
        </div>
      </div>
      {isLoading && <div className="w-100 center-text p-20">Loading...</div>}
      {errorMsg && <div className="w-100 center-text p-20">{errorMsg}</div>}
    </div>
  );
};

export default ViewProfile;

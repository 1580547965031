import React, { useEffect } from 'react';

import Greeting from '../common/Greeting';
import LocationDetails from '../layouts/LocationDetails';

const Dashboard = ({ history }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Dashboard';
  }, []);

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-baseline">
        <Greeting />
        <LocationDetails />
      </div>
    </div>
  );
};

export default Dashboard;

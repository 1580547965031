/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-closing-bracket-location */
import React, { useState, useEffect } from 'react';
import { IoMdClose } from 'react-icons/io';
import Loader from '../../assets/svg/loading.svg';
import notification from '../../utility/notification';
import { toggleScroll } from '../../utility/general';
import { decodeToken } from '../../utility/auth';

// Api
import { put, post, get } from '../../api-services/fetch';

// Inputs
import TextInput from '../inputs/TextInput';
import SelectInput from '../inputs/SelectInput';

const ModulePartForm = ({ details, updateList, currentModule, reload, setReload, closeModal }) => {
  const [modules, setModules] = useState([]);
  const [module, setModule] = useState(0);
  const [modulePartName, setModulePartName] = useState('');
  const [formType, setFormType] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    toggleScroll();
    if (details && details.id) {
      setFormType('edit');
      console.log('logging current module', currentModule);
      setModule(currentModule || 0);
      setModulePartName(details.modulePartName || '');
    } else {
      setFormType('create');
    }

    return () =>
      setTimeout(() => {
        toggleScroll();
      }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  useEffect(() => {
    getCategories({});
  }, []);

  const getCategories = async ({ query = null }) => {
    const res = await get('module', { query }, null, true);

    console.log('Logging Get category Res', res);
    if (res && res.status === 200) {
      const { data } = res;
      if (data.length > 0) {
        const modulesList = data.map((item) => ({ name: item.urlName, value: item.id }));
        modulesList.unshift({ name: 'Select item...', value: '' });
        setModules(modulesList);
      }
    }
  };

  const submitForm = async () => {
    const body = {
      moduleId: module,
      modulePartName,
      userId: decodeToken('id').id,
      id: details.id
    };

    console.log('logging Body', body);
    const error = validateForm(body);

    if (error) {
      notification({
        title: 'Invalid Entry',
        message: error,
        type: 'danger'
      });
      return;
    }

    setIsLoading(true);
    // eslint-disable-next-line operator-linebreak
    const res =
      formType === 'create'
        ? await post('modulePart', body, true)
        : await put('modulePart', body, details.id, true);
    console.log(res);
    setIsLoading(false);

    if (res && res.status === 200) {
      const { data } = res;
      if (
        data &&
        (data.message.toLowerCase() === 'record saved' ||
          data.message.toLowerCase() === 'record updated')
      ) {
        notification({
          title: `Successful Module Part ${formType === 'create' ? 'Creation' : 'Update'}`,
          message: data.message,
          type: 'success'
        });
        // update Table
        setReload(!reload);
        // updateList(data, formType === 'edit');
        if (formType === 'edit') {
          closeModal();
          return;
        }
        setModule('');
        setModulePartName('');
      } else {
        notification({
          title: `Module Part ${formType === 'create' ? 'Creation' : 'Update'} Error`,
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Network Error',
        message: `Something went wrong while ${
          formType === 'create' ? 'creating' : 'updating'
        } an Module Part. Please, try again later.`,
        type: 'danger'
      });
    }
  };
  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box max-w-400">
        <div className="content">
          <SelectInput
            name="module"
            options={modules || []}
            value={module}
            onChange={(e) => setModule(Number(e.target.value))}
            className="w-100 m-b-10"
            label="Module*"
          />
          <TextInput
            label="Module Part Name*"
            value={modulePartName}
            onChange={(e) => setModulePartName(e.target.value)}
            className="w-100 m-b-10"
            name="modulePartName"
          />
          <button
            type="button"
            className="btn btn-large w-100"
            onClick={submitForm}
            disabled={isLoading}>
            {!isLoading && `${formType === 'create' ? 'Save' : 'Update'} Module Part`}
            {isLoading && `${formType === 'create' ? 'Saving' : 'Updating'} Module Part ...`}
            {isLoading && <img src={Loader} className="btn-loading" alt="Loading..." />}
          </button>
        </div>
      </div>
    </div>
  );
};

const validateForm = ({ moduleId, modulePartName }) => {
  if (!moduleId) return 'Please, select a Module';
  if (!modulePartName) return 'Please, enter the Module Part Name';
  return null;
};

export default ModulePartForm;

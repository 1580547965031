/* eslint-disable react/destructuring-assignment */
import React from 'react';

import '../../assets/css/select-search.css';

import SelectSearch from 'react-select-search';

const AutoComplete = ({
  className, name, label, options, onChange, disabled,
  value, getOptions, multiple, defaultValue
}) => (
  <div className={`${className || ''} form-group`}>
    <label htmlFor={name}>
      {label}
    </label>
    <SelectSearch
      closeOnSelect
      printOptions="on-focus"
      search
      multiple={multiple}
      value={value}
      disabled={disabled}
      placeholder="..."
      options={options || []}
      getOptions={getOptions}
      onChange={onChange}
      defaultValue = {defaultValue}
    />
  </div>
);

export default AutoComplete;

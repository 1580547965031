/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable operator-linebreak */
import React, { useState, useEffect } from 'react';
// Icons
// import { RiAddLine } from 'react-icons/ri';
import { BiEditAlt } from 'react-icons/bi';
import { GiTrashCan } from 'react-icons/gi';
import LocationDetails from '../layouts/LocationDetails';
import ModulePartRoleForm from '../layouts/ModulePartRoleForm';
import IsLoading from '../common/IsLoading';
import notification from '../../utility/notification';

// inputs
import SearchInput from '../inputs/SearchInput';

// Api
import { del, get } from '../../api-services/fetch';
import Greeting from '../common/Greeting';

const ModulePartRole = () => {
  // eslint-disable-next-line no-unused-vars
  const [showFormModal, setShowFormModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState([]);
  const [currentModuleParts, setCurrentModuleParts] = useState([]);
  const [reload, setReload] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [details, setDetails] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Module Part Role';

    // getModulePartRoleList({});
  }, [reload]);

  const closeModal = () => {
    setSelectedRecord([]);
    setShowFormModal(false);
  };
  const getModulePartRoleListByModule = async ({ query = null }, moduleId) => {
    setIsLoading(true);
    setErrorMsg('');

    const res = await get('modulePartRole', query, moduleId, true);

    console.log('logging module part role list by id res', res);
    if (res && res.status === 200) {
      const { data } = res;

      if (data) {
        setDetails(data);
        if (data.length < 1) {
          if (query) {
            setErrorMsg('Module Part not found');
          } else {
            setErrorMsg('No Module Part Role yet');
          }
        }
      } else {
        data.message
          ? setErrorMsg(data.message)
          : setErrorMsg('Something has gone wrong. Please, try again.');
      }
    } else {
      setErrorMsg('Something has gone wrong. Please, try again.');
    }
    setIsLoading(false);
  };
  // const getModulePartRoleList = async ({ query = null }) => {
  //   // console.log(query);
  //   setIsLoading(true);
  //   setErrorMsg('');
  //   const res = await get('modulePartRole', { query }, null, true);

  //   console.log(res);

  //   if (res && res.status === 200) {
  //     const { data } = res;
  //     // if (res.status === 200) {
  //     if (data) {
  //       setDetails(data);
  //       if (data.length < 1) {
  //         if (query) {
  //           setErrorMsg('Module Part not found');
  //         } else {
  //           setErrorMsg('No Module Part Role yet');
  //         }
  //       }
  //     } else {
  //       data.message
  //         ? setErrorMsg(data.message)
  //         : setErrorMsg('Something has gone wrong. Please, try again.');
  //     }
  //     // } else {
  //     // setErrorMsg('Something has gone wrong. Please, try again.');
  //     // }
  //   } else {
  //     setErrorMsg('Something has gone wrong. Please, try again.');
  //   }
  //   setIsLoading(false);
  // };

  const updateList = (newItem, isUpdate) => {
    if (isUpdate) {
      const newList = details.map((item) => (item.id === newItem.id ? newItem : item));
      setDetails(newList);
    } else {
      const newList = [...details];
      newList.unshift(newItem);
      setDetails(newList);
    }
  };

  const showEditForm = async (id) => {
    const selected = details.filter((item) => id === item.id);
    console.log('Logging Selected', selected);
    if (selected.length > 0) {
      setSelectedRecord(selected[0]);
      setShowFormModal(true);
      const res = await get('modulePartRole', { query: null }, selected[0].moduleId, true);

      if (res && res.status === 200) {
        const { data } = res;
        console.log('logging out Data', data);
        if (data && data.length > 0) {
          console.log(
            'module parts data',
            data.map((item) => ({ name: item.modulePartName, value: item.id }))
          );
          const modulesList = data.map((item) => ({ name: item.modulePartName, value: item.id }));
          modulesList.unshift({ name: 'Select item...', value: '' });
          setCurrentModuleParts(modulesList);
        }
      }

      console.log('select Part role', res);
    }
  };

  const deleteItem = async (id, name) => {
    const confirmAction = confirm(`Click OK to confirm the deletion of "${name}"`);
    if (!confirmAction) return;
    setIsDeleting(true);
    const res = await del('modulePartRole', id, true);

    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (
        data &&
        (data.message.toLowerCase() === 'record saved' ||
          data.message.toLowerCase() === 'record updated' ||
          data.message.toLowerCase() === 'record deleted')
      ) {
        notification({
          title: 'Successful Module Category Deletion',
          message: data.message,
          type: 'success'
        });
        const others = details.filter((item) => id !== item.id);
        setDetails(others);
      } else {
        notification({
          title: 'Module Category Deletion Error',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Network Error',
        message: 'Something went wrong while deleting a Module Part. Please, try again later.',
        type: 'danger'
      });
    }
    setIsDeleting(false);
  };
  return (
    <div className="w-100">
      <div className="w-100 flex space-between">
      <Greeting />
        <LocationDetails />
      </div>
      <div className="w-100 flex space-between flex-v-center m-t-20">
        <h2>Module Part Role</h2>
        <SearchInput
          name="searchText"
          // value={searchText}
          // onChange={handleSearch}
          placeholder="Search list..."
        />
      </div>
      <div className="flex">
        <div className="col-4 p-t-20">
          <ModulePartRoleForm
            details={selectedRecord}
            updateList={updateList}
            closeModal={closeModal}
            currentModuleParts={currentModuleParts}
            reload={reload}
            setReload={setReload}
            getDetails={getModulePartRoleListByModule}
          />
        </div>
        <div className="col-8">
          <div className="table-view m-t-10">
            <div className="table-header">
              <div className="table-row">
                <div className="th">
                  <p>Module Part Name</p>
                </div>
                <div className="th">
                  <p>Last Updated By</p>
                </div>
                <div className="th">
                  <p>Last Updated</p>
                </div>
                <div className="th text-right">Action</div>
                {/* <div className="th">
              <button
                className="add-button pointer"
                type="button"
                onClick={() => setShowFormModal(true)}>
                <RiAddLine />
                Add Module Part Role
              </button>
            </div> */}
              </div>
            </div>
            <div className="table-body">
              {!(isLoading || errorMsg) &&
                details &&
                details.map((item) => (
                  <div className="table-row" key={item.id}>
                    <div className="td">{item.name}</div>
                    <div className="td">{item.staffname || item.createdBy}</div>
                    <div className="td">{item.timeCreated}</div>
                    <div className="td actions">
                      <button
                        type="button"
                        className="pointer"
                        onClick={() => showEditForm(item.id)}>
                        <BiEditAlt />
                      </button>
                      <button
                        type="button"
                        className="m-l-10 del pointer"
                        onClick={() => deleteItem(item.id, item.name)}>
                        <GiTrashCan />
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {isLoading && <div className="w-100 center-text p-20">Loading...</div>}
          {errorMsg && <div className="w-100 center-text p-20">{errorMsg}</div>}
        </div>
      </div>

      {(isLoading || isDeleting) && <IsLoading message={isDeleting ? 'Deleting...' : null} />}
    </div>
  );
};

export default ModulePartRole;

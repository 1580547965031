import React from 'react';
import { MdSkipPrevious, MdSkipNext, MdNavigateNext, MdNavigateBefore } from 'react-icons/md';

const TablePaginationActions = (props) => {
  const { count, page, rowsPerPage, onChangePage, changeRowsPerPage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className='w-100 '>
      <div className='flex justify-content-end '>
        <div className='flex justify-content-end m-r-50'>
          <div className='flex flex-h-center flex-v-center m-r-20'>
            <p className='m-r-10'>Rows Per Page:</p>
            <select
              name='rowsPerPage'
              value={rowsPerPage}
              onChange={(e) => {
                handleFirstPageButtonClick();
                changeRowsPerPage(e.target.value);
              }}
              style={{ width: '75px', height: '25px' }}>
              {/* <option value={5}>5</option> */}
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={25}>25</option>
              <option value={30}>30</option>
              <option value={40}>40</option>
              <option value={50}>50</option>
            </select>
          </div>
          <button
            className='table-pagination-btn tooltip pointer'
            onClick={(e) => handleFirstPageButtonClick(e)}
            disabled={page === 0}>
            <MdSkipPrevious fontSize={'27px'} />
            <span className='tooltiptext'>Go to First Page</span>
          </button>
          <button
            className='table-pagination-btn tooltip pointer'
            onClick={(e) => handleBackButtonClick(e)}
            disabled={page === 0}>
            <MdNavigateBefore fontSize={'27px'} />
            <span className='tooltiptext'>Previous</span>
          </button>
          <button
            className='table-pagination-btn tooltip pointer'
            onClick={(e) => handleNextButtonClick(e)}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
            <MdNavigateNext fontSize={'27px'} />
            <span className='tooltiptext'>Next</span>
          </button>
          <div className='tooltip'>
            <button
              className='table-pagination-btn pointer'
              onClick={(e) => handleLastPageButtonClick(e)}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
              <MdSkipNext fontSize={'27px'} />
            </button>
            <span className='tooltiptext'>Go to Last Page</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TablePaginationActions;

/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable operator-linebreak */
import React, { useEffect, useState } from 'react';
// Icons
import { RiAddLine } from 'react-icons/ri';
import { BiEditAlt } from 'react-icons/bi';
import { GiTrashCan } from 'react-icons/gi';
import LocationDetails from '../layouts/LocationDetails';
import ModuleForm from '../layouts/ModuleForm';
import IsLoading from '../common/IsLoading';
import notification from '../../utility/notification';

// Api
import { del, get } from '../../api-services/fetch';

// inputs
import SelectInput from '../inputs/SelectInput';
import Greeting from '../common/Greeting';

const Module = () => {
  const [reload, setReload] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [details, setDetails] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [ID, setID] = useState(0);

  const [modules, setModules] = useState([]);
  const [module, setModule] = useState(0);
  // const [searchText, setSearchText] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Module Setup';

    // getModuleList({});
    getModules({});
  }, [reload]);

  const closeModal = () => {
    setSelectedRecord([]);
    setShowFormModal(false);
  };

  const updateList = (newItem, isUpdate) => {
    if (isUpdate) {
      const newList = details.map((item) => (item.id === newItem.id ? newItem : item));
      setDetails(newList);
    } else {
      const newList = [...details];
      newList.unshift(newItem);
      setDetails(newList);
    }
  };

  const showEditForm = (id) => {
    setID(id);
    const selected = details.filter((item) => id === item.id);
    console.log('Logging Selected', selected);
    if (selected.length > 0) {
      setSelectedRecord(selected[0]);
      setShowFormModal(true);
    }
  };

  const getModules = async ({ query = null }) => {
    setIsLoading(true);
    const res = await get('moduleCategory', { query }, null, true);

    console.log('Logging Get category Res', res);
    if (res && res.status === 200) {
      const { data } = res;
      if (data.length > 0) {
        const modulesList = data.map((item) => ({ name: item.category, value: item.id }));
        modulesList.unshift({ name: 'Select item...', value: '' });
        setModules(modulesList);
      }
    }
    setIsLoading(false);
  };

  const getModuleList = async (query) => {
    console.log('logging query inside function: ', query);
    setIsLoading(true);
    setErrorMsg('');
    const res = await get('module', query, null, true);

    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      // if (res.status === 200) {
      if (data) {
        setDetails(data);
        if (data.length < 1) {
          if (query) {
            setErrorMsg('Module not found');
          } else {
            setErrorMsg('No Module yet');
          }
        }
      } else {
        data.message
          ? setErrorMsg(data.message)
          : setErrorMsg('Something has gone wrong. Please, try again.');
      }
      // } else {
      // setErrorMsg('Something has gone wrong. Please, try again.');
      // }
    } else {
      setErrorMsg('Something has gone wrong. Please, try again.');
    }
    setIsLoading(false);
  };

  const deleteItem = async (id, name) => {
    const confirmAction = confirm(`Click OK to confirm the deletion of "${name}"`);
    if (!confirmAction) return;
    setIsDeleting(true);
    const res = await del('module', id, true);

    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data && data.message === 'Record Deleted') {
        notification({
          title: 'Successful Module Category Deletion',
          message: data.message,
          type: 'success'
        });
        const others = details.filter((item) => id !== item.id);
        setDetails(others);
      } else {
        notification({
          title: 'Module Category Deletion Error',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Network Error',
        message: 'Something went wrong while deleting an MDA. Please, try again later.',
        type: 'danger'
      });
    }
    setIsDeleting(false);
  };

  useEffect(() => {
    console.log('logging module id', module);
    module && getModuleList({ id: module });
  }, [reload, module]);

  return (
    <div className="w-100">
      <div className="w-100 flex space-between">
      <Greeting />
        <LocationDetails />
      </div>
      <div className="w-100 flex space-between flex-v-center m-t-20">
        <h2>Module Setup</h2>
        <SelectInput
          name="module"
          label="Module Category*"
          className="w-35 bg-transparent"
          value={module}
          options={modules}
          onChange={(e) => setModule(Number(e.target.value))}
        />
      </div>
      <div className="table-view m-t-10">
        <div className="table-header">
          <div className="table-row">
            <div className="th">
              <p>Module Name</p>
            </div>
            <div className="th">
              <p>Module Address</p>
            </div>
            <div className="th">
              <p>Last Updated</p>
            </div>
            <div className="th">
              <p>Last Updated By</p>
            </div>
            <div className="th">
              <button
                className="add-button pointer"
                type="button"
                onClick={() => setShowFormModal(true)}>
                <RiAddLine />
                Add Module
              </button>
            </div>
          </div>
        </div>
        <div className="table-body">
          {!(isLoading || errorMsg) &&
            details &&
            details.map((item) => (
              <div className="table-row" key={item.id}>
                <div className="td">{item.urlName}</div>
                <div className="td">{item.address}</div>
                <div className="td">{item.timeCreated}</div>
                <div className="td">{item.staffname}</div>
                <div className="td actions">
                  <button type="button" className="pointer" onClick={() => showEditForm(item.id)}>
                    <BiEditAlt />
                  </button>
                  <button
                    type="button"
                    className="m-l-10 del pointer"
                    onClick={() => deleteItem(item.id, item.urlName)}>
                    <GiTrashCan />
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
      {isLoading && <div className="w-100 center-text p-20">Loading...</div>}
      {errorMsg && <div className="w-100 center-text p-20">{errorMsg}</div>}
      {(isLoading || isDeleting) && <IsLoading message={isDeleting ? 'Deleting...' : null} />}
      {showFormModal && (
        <ModuleForm
          details={selectedRecord}
          updateList={updateList}
          closeModal={closeModal}
          setReload={setReload}
          reload={reload}
          ID={ID}
        />
      )}
    </div>
  );
};

export default Module;

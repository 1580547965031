const employeeLevels = [
  {
    name: 'Select item...',
    value: ''
  },
  {
    name: 'Graduate Trainee',
    value: 'Graduate Trainee'
  },
  {
    name: 'Level 1',
    value: 'Level 1'
  },
  {
    name: 'Level 2',
    value: 'Level 2'
  },
  {
    name: 'Level 3',
    value: 'Level 3'
  },
  {
    name: 'Level 4',
    value: 'Level 4'
  },
  {
    name: 'Level 5',
    value: 'Level 5'
  },
  {
    name: 'Level 6',
    value: 'Level 6'
  },
  {
    name: 'Level 7',
    value: 'Level 7'
  },
  {
    name: 'Level 8',
    value: 'Level 8'
  },
  {
    name: 'Level 9',
    value: 'Level 9'
  },
  {
    name: 'Level 10',
    value: 'Level 10'
  },
  {
    name: 'Level 11',
    value: 'Level 11'
  },
  {
    name: 'Level 12',
    value: 'Level 12'
  },
  {
    name: 'Level 13',
    value: 'Level 13'
  },
  {
    name: 'Level 14',
    value: 'Level 14'
  },
  {
    name: 'Level 15',
    value: 'Level 15'
  },
  {
    name: 'Level 16',
    value: 'Level 16'
  },
  {
    name: 'Level 17',
    value: 'Level 17'
  },
  {
    name: 'Level 1 (Non Civil)',
    value: 'Level 1 (Non Civil)'
  },
  {
    name: 'Level 2 (Non Civil)',
    value: 'Level 2 (Non Civil)'
  },
  {
    name: 'Level 3 (Non Civil)',
    value: 'Level 3 (Non Civil)'
  },
  {
    name: 'Level 4 (Non Civil)',
    value: 'Level 4 (Non Civil)'
  },
  {
    name: 'Level 5 (Non Civil)',
    value: 'Level 5 (Non Civil)'
  },
  {
    name: 'Level 6 (Non Civil)',
    value: 'Level 6 (Non Civil)'
  },
  {
    name: 'Level 7 (Non Civil)',
    value: 'Level 7 (Non Civil)'
  },
  {
    name: 'Level 8 (Non Civil)',
    value: 'Level 8 (Non Civil)'
  },
  {
    name: 'Level 9 (Non Civil)',
    value: 'Level 9 (Non Civil)'
  },
  {
    name: 'Level 10 (Non Civil)',
    value: 'Level 10 (Non Civil)'
  },
  {
    name: 'Level 11 (Non Civil)',
    value: 'Level 11 (Non Civil)'
  },
  {
    name: 'Level 12 (Non Civil)',
    value: 'Level 12 (Non Civil)'
  },
  {
    name: 'Level 13 (Non Civil)',
    value: 'Level 13 (Non Civil)'
  },
  {
    name: 'Level 14 (Non Civil)',
    value: 'Level 14 (Non Civil)'
  },
  {
    name: 'Level 15 (Non Civil)',
    value: 'Level 15 (Non Civil)'
  },
  {
    name: 'Level 16 (Non Civil)',
    value: 'Level 16 (Non Civil)'
  },
  {
    name: 'Level 17 (Non Civil)',
    value: 'Level 17 (Non Civil)'
  },
  {
    name: 'JS 1',
    value: 'JS 1'
  },
  {
    name: 'JS 2',
    value: 'JS 2'
  },
  {
    name: 'JS 3',
    value: 'JS 3'
  },
  {
    name: 'JS 4',
    value: 'JS 4'
  },
  {
    name: 'JS 5',
    value: 'JS 5'
  },
  {
    name: 'JS 6',
    value: 'JS 6'
  },
  {
    name: 'JS 7',
    value: 'JS 7'
  },
  {
    name: 'JS 8',
    value: 'JS 8'
  },
  {
    name: 'JS 9',
    value: 'JS 9'
  },
  {
    name: 'JS 10',
    value: 'JS 10'
  },
  {
    name: 'Consolidated',
    value: 'Consolidated'
  }
];

export default employeeLevels;

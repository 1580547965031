// /* eslint-disable operator-linebreak */
// /* eslint-disable function-paren-newline */
// /* eslint-disable implicit-arrow-linebreak */
// /* eslint-disable react/jsx-closing-bracket-location */
// /* eslint-disable max-len */
// import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

// import Loader from '../../assets/svg/loading.svg';
// import coatOfArm from '../../assets/images/coat-of-arm.png';
// import Footer from '../common/Footer';

// import { post } from '../../api-services/fetch';
// import { getLocation } from '../../api-services/otherApis';
// import { encodeToken } from '../../utility/auth';
// import notification from '../../utility/notification';

// class Home extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isLoggingin: false,
//       email: '',
//       password: ''
//     };
//   }

//   async componentDidMount() {
//     const sessionExpired = localStorage.getItem('se');
//     if (sessionExpired) {
//       notification({
//         title: 'SESSION TIMEOUT',
//         message: 'Your session token has expired. Please, re-login to start a new session.',
//         type: 'danger',
//         duration: 0,
//         onRemoval: () => localStorage.removeItem('se')
//       });
//     }
//     await this.location();
//   }

//   location = async () => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         async (position) => {
//           const pos = {
//             lat: position.coords.latitude,
//             lng: position.coords.longitude
//           };

//           const res = await getLocation(pos);
//           console.log(res);

//           if (res.status === 'OK') {
//             if (res.results[0]) {
//               const { results } = res;
//               const neighborhood = results.filter((item) =>
//                 item.types.includes('administrative_area_level_2')
//               );
//               console.log(neighborhood[0].formatted_address);
//               encodeToken({ gl: neighborhood[0].formatted_address }, 'gl');
//             }
//           } else {
//             console.log(res.error_message);
//           }
//         },
//         (error) => {
//           console.log(error);
//         }
//       );
//     } else {
//       notification({
//         title: 'Geo-Location not Supported',
//         message: 'Your browser does not support Geo-Location',
//         type: 'danger'
//       });
//     }
//   };

//   handleKeyPress = (e) => {
//     const { isLoggingin } = this.state;
//     if (!isLoggingin && e.which === 13) {
//       this.handleSignin();
//     }
//   };

//   handleSignin = async () => {
//     const { email, password } = this.state;

//     if (!(email && password)) {
//       notification({
//         title: 'Invalid Entry',
//         message: 'Please, enter your email and password.',
//         type: 'danger'
//       });
//       return;
//     }
//     if (email && !/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(email)) {
//       notification({
//         title: 'Invalid Entry',
//         message: 'Please, enter a valid email',
//         type: 'danger'
//       });
//       return;
//     }

//     this.setState({ isLoggingin: true });

//     const res = await post('login', { email, password }, false);

//     console.log(res);
//     this.setState({ isLoggingin: false });

//     if (res && res.status === 200) {
//       const { data } = res;
//       if (data.code === 1) {
//         this.setState({ isLoggingin: true });
//         const { history } = this.props;

//         history.listen((newLocation, action) => {
//           if (action === 'PUSH') {
//             if (
//               newLocation.pathname !== this.currentPathname ||
//               newLocation.search !== this.currentSearch
//             ) {
//               // Save new location
//               this.currentPathname = newLocation.pathname;
//               this.currentSearch = newLocation.search;

//               // Clone location object and push it to history
//               history.push({
//                 pathname: newLocation.pathname,
//                 search: newLocation.search
//               });
//             }
//           } else {
//             // Send user back if they try to navigate back
//             history.go(1);
//           }
//         });

//         data.token && encodeToken({ t: data.token }, 't');
//         data.data.firstName && encodeToken({ fn: data.data.firstName }, 'fn');
//         data.data.lastName && encodeToken({ ln: data.data.lastName }, 'ln');
//         data.data.lastLoginTime && encodeToken({ lo: data.data.lastLoginTime }, 'lo');
//         data.data.roles && encodeToken({ r: data.data.roles[0].name }, 'r');
//         data.data.userId && encodeToken({ id: data.data.userId }, 'id');
//         encodeToken({ il: true }, 'il'); // isLoggedin

//         setTimeout(() => history.push('/admin-portal/mda-setup'), 2000);
//       } else {
//         notification({
//           title: 'Login Error',
//           message: data.message,
//           type: 'danger'
//         });
//       }
//     } else {
//       notification({
//         title: 'Network Error',
//         message: 'Something has gone wrong. Please, try again.',
//         type: 'danger'
//       });
//     }
//   };

//   handleChange = (e) => {
//     const { name, value } = e.target;
//     this.setState({
//       [name]: value
//     });
//   };

//   render() {
//     const { isLoggingin, email, password } = this.state;

//     return (
//       <div className="w-100">
//         <div className="banner">
//           <div className="login">
//             <div className="coat-of-arm">
//               <img src={coatOfArm} alt="" />
//               <h2>EDO STATE</h2>
//               <h4>GOVERNMENT</h4>
//             </div>
//             <div className="form">
//               <div className="login-form-group">
//                 <input
//                   type="email"
//                   placeholder="Email Address"
//                   name="email"
//                   value={email}
//                   onChange={this.handleChange}
//                   onKeyPress={this.handleKeyPress}
//                 />
//               </div>
//               <div className="login-form-group">
//                 <input
//                   type="password"
//                   placeholder="Password"
//                   name="password"
//                   value={password}
//                   onChange={this.handleChange}
//                   onKeyPress={this.handleKeyPress}
//                 />
//               </div>
//               <button
//                 className="m-b-10 pointer p-r"
//                 type="button"
//                 onClick={this.handleSignin}
//                 disabled={isLoggingin}>
//                 LOGIN
//                 {isLoggingin && <img src={Loader} className="btn-loading" alt="Loading..." />}
//               </button>
//               <Link to="/forget-password">Forgot password?</Link>
//             </div>
//           </div>
//         </div>
//         <section>
//           <div className="intro center-text content">
//             <div>
//               <p>
//                 It is along established fact that a reader will be distracted by the readable
//                 content of a page when looking at its layout.The point of using Lorem Ipsum is that
//                 it has a more or less normal distribution of letters.
//               </p>
//             </div>
//           </div>
//         </section>
//         <section>
//           <div className="content">
//             <div className="row">
//               {[{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }].map((item) => (
//                 <div className="col-4" key={item.id}>
//                   <div className="dept-news">
//                     <h4>INTERNAL NEWS</h4>
//                     <div className="headline-list">
//                       <div className="headline flex m-t-20">
//                         <div>
//                           <div className="date">
//                             <p className="m">JAN</p>
//                             <p className="d">13</p>
//                           </div>
//                         </div>
//                         <p className="title">
//                           Edo Govt boosts COVID-19 response as Obaseki commissions 4th molecular
//                           laboratory
//                         </p>
//                       </div>
//                       <div className="headline flex m-t-20">
//                         <div>
//                           <div className="date">
//                             <p className="m">MAR</p>
//                             <p className="d">09</p>
//                           </div>
//                         </div>
//                         <p className="title">
//                           Helicopter crash: Obaseki condoles with Sanwo-Olu, Lagos govt over crash
//                         </p>
//                       </div>
//                       <div className="headline flex m-t-20">
//                         <div>
//                           <div className="date">
//                             <p className="m">AUG</p>
//                             <p className="d">22</p>
//                           </div>
//                         </div>
//                         <p className="title">
//                           Obaseki’s reform: Marine Police base ready, as hope for Benin River Port
//                           rises
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </section>
//         <section>
//           <div className="content">
//             <div className="row">
//               <div className="col-3">
//                 <div className="bottom">
//                   <h2>Vision Statement</h2>
//                   <p>
//                     To make Edo State an Economic hub and No 1 choice for investors both local and
//                     foreign.
//                   </p>
//                 </div>
//               </div>
//               <div className="col-3">
//                 <div className="bottom">
//                   <h2>Mission Statement</h2>
//                   <p>
//                     To develop a modern and progressive Edo State where every citizen is empowered
//                     with the opportunity to live life in its fullness.
//                   </p>
//                 </div>
//               </div>
//               <div className="col-3">
//                 <div className="bottom">
//                   <h2>Corporate Strategy</h2>
//                   <ol>
//                     <li>Social Welfare</li>
//                     <li>Institutional Reform</li>
//                     <li>Infrastructural Development</li>
//                     <li>Environmental Sustainability</li>
//                     <li>Economic Revolution</li>
//                     <li>Culture & Tourism </li>
//                   </ol>
//                 </div>
//               </div>
//               <div className="col-3">
//                 <div className="bottom">
//                   <h2>Resource Centre</h2>
//                   <ul>
//                     <li>edostate.gov.ng</li>
//                     <li>godwinobaseki.com</li>
//                     <li>edojobs.edostate.gov.ng</li>
//                     <li>eirs.org</li>
//                     <li>edogis.org</li>
//                     <li>Edojudiciary.org</li>
//                   </ul>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//         <Footer />
//       </div>
//     );
//   }
// }

// export default Home;

/* eslint-disable operator-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable max-len */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Loader from '../../assets/svg/loading.svg';
import coatOfArm from '../../assets/images/coat-of-arm.png';
import Footer from '../common/Footer';

import { post } from '../../api-services/fetch';
import { getLocation } from '../../api-services/otherApis';
import { encodeToken } from '../../utility/auth';
import notification from '../../utility/notification';
import {AiOutlineEyeInvisible, AiOutlineEye} from 'react-icons/ai';


class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggingin: false,
      email: '',
      password: '',
      hider:false,
      inputState:"password",
    };

  }

  // async componentDidMount() {
  //   const sessionExpired = localStorage.getItem('se');
  //   if (sessionExpired) {
  //     notification({
  //       title: 'SESSION TIMEOUT',
  //       message: 'Your session token has expired. Please, re-login to start a new session.',
  //       type: 'danger',
  //       duration: 0,
  //       onRemoval: () => localStorage.removeItem('se')
  //     });
  //   }
  //   await this.location();
  // }

  location = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };

          const res = await getLocation(pos);
          console.log(res);

          if (res.status === 'OK') {
            if (res.results[0]) {
              const { results } = res;
              const neighborhood = results.filter((item) =>
                item.types.includes('administrative_area_level_2')
              );
              console.log(neighborhood[0].formatted_address);
              encodeToken({ gl: neighborhood[0].formatted_address }, 'gl');
            }
          } else {
            console.log(res.error_message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      notification({
        title: 'Geo-Location not Supported',
        message: 'Your browser does not support Geo-Location',
        type: 'danger'
      });
    }
  };

  handleKeyPress = (e) => {
    const { isLoggingin } = this.state;
    if (!isLoggingin && e.which === 13) {
      this.handleSignin();
    }
  };

  handleSignin = async () => {
    const { email, password } = this.state;

    if (!(email && password)) {
      notification({
        title: 'Invalid Entry',
        message: 'Please, enter your email and password.',
        type: 'danger'
      });
      return;
    }
    if (email && !/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(email)) {
      notification({
        title: 'Invalid Entry',
        message: 'Please, enter a valid email',
        type: 'danger'
      });
      return;
    }

    this.setState({ isLoggingin: true });

    const res = await post('login', { email, password }, false);

    console.log(res);
    this.setState({ isLoggingin: false });

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        this.setState({ isLoggingin: true });
        const { history } = this.props;

        history.listen((newLocation, action) => {
          if (action === 'PUSH') {
            if (
              newLocation.pathname !== this.currentPathname ||
              newLocation.search !== this.currentSearch
            ) {
              // Save new location
              this.currentPathname = newLocation.pathname;
              this.currentSearch = newLocation.search;

              // Clone location object and push it to history
              history.push({
                pathname: newLocation.pathname,
                search: newLocation.search
              });
            }
          } else {
            // Send user back if they try to navigate back
            history.go(1);
          }
        });

        data.token && encodeToken({ t: data.token }, 't');
        data.data.firstName && encodeToken({ fn: data.data.firstName }, 'fn');
        data.data.lastName && encodeToken({ ln: data.data.lastName }, 'ln');
        data.data.lastLoginTime && encodeToken({ lo: data.data.lastLoginTime }, 'lo');
        data.data.roles && encodeToken({ r: data.data.roles[0].name }, 'r');
        data.data.userId && encodeToken({ id: data.data.userId }, 'id');
        encodeToken({ il: true }, 'il'); // isLoggedin

        setTimeout(() => history.push('/admin-portal/mda-setup'), 2000);
      } else {
        notification({
          title: 'Login Error',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Network Error',
        message: 'Something has gone wrong. Please, try again.',
        type: 'danger'
      });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  render() {
const hidePassword = ()=>{
this.setState({hider:!this.state.hider})
if(!this.state.hider)
this.setState({inputState:"text"})
else
this.setState({inputState:"password"})

// console.log('hellyyy')
// console.log(this.state.hider)
}

    const { isLoggingin, email, password } = this.state;

    return (
      <div className="w-100">
        <div className="banner">
          <div className="login">
            <div className="coat-of-arm">
              <img src={coatOfArm} alt="" />
              <h2>EDO STATE</h2>
              <h4>GOVERNMENT</h4>
            </div>
            <div className="form">
              <div className="login-form-group">
                <input
                  type="email"
                  placeholder="Email Address"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                  onKeyPress={this.handleKeyPress}
                />
              </div>
              <div className="login-form-group" style={{position:"relative"}}>
                <input
                  type={this.state.inputState}
                  placeholder="Password"
                  name="password"
                  value={password}
                  onChange={this.handleChange}
                  onKeyPress={this.handleKeyPress}
                />
                <div className='login-form-groupz' onClick={hidePassword}>
                  
                 { (this.state.hider)?<AiOutlineEye style={{fontSize:"25px"}}/>:
                 <AiOutlineEyeInvisible style={{fontSize:"25px"}}/>
                         
                }
                  
                  </div>
              </div>
              <button
                className="m-b-10 pointer p-r"
                type="button"
                onClick={this.handleSignin}
                disabled={isLoggingin}>
                LOGIN
                {isLoggingin && <img src={Loader} className="btn-loading" alt="Loading..." />}
              </button>
              <Link to="/forget-password">Forgot password?</Link>
            </div>
          </div>
        </div>
        <section>
          <div className="intro center-text content">
            <div>
              <p>
                It is along established fact that a reader will be distracted by the readable
                content of a page when looking at its layout.The point of using Lorem Ipsum is that
                it has a more or less normal distribution of letters.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="content">
            <div className="row">
              {[{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }].map((item) => (
                <div className="col-4" key={item.id}>
                  <div className="dept-news">
                    <h4>INTERNAL NEWS</h4>
                    <div className="headline-list">
                      <div className="headline flex m-t-20">
                        <div>
                          <div className="date">
                            <p className="m">JAN</p>
                            <p className="d">13</p>
                          </div>
                        </div>
                        <p className="title">
                          Edo Govt boosts COVID-19 response as Obaseki commissions 4th molecular
                          laboratory
                        </p>
                      </div>
                      <div className="headline flex m-t-20">
                        <div>
                          <div className="date">
                            <p className="m">MAR</p>
                            <p className="d">09</p>
                          </div>
                        </div>
                        <p className="title">
                          Helicopter crash: Obaseki condoles with Sanwo-Olu, Lagos govt over crash
                        </p>
                      </div>
                      <div className="headline flex m-t-20">
                        <div>
                          <div className="date">
                            <p className="m">AUG</p>
                            <p className="d">22</p>
                          </div>
                        </div>
                        <p className="title">
                          Obaseki’s reform: Marine Police base ready, as hope for Benin River Port
                          rises
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section>
          <div className="content">
            <div className="row">
              <div className="col-3">
                <div className="bottom">
                  <h2>Vision Statement</h2>
                  <p>
                    To make Edo State an Economic hub and No 1 choice for investors both local and
                    foreign.
                  </p>
                </div>
              </div>
              <div className="col-3">
                <div className="bottom">
                  <h2>Mission Statement</h2>
                  <p>
                    To develop a modern and progressive Edo State where every citizen is empowered
                    with the opportunity to live life in its fullness.
                  </p>
                </div>
              </div>
              <div className="col-3">
                <div className="bottom">
                  <h2>Corporate Strategy</h2>
                  <ol>
                    <li>Social Welfare</li>
                    <li>Institutional Reform</li>
                    <li>Infrastructural Development</li>
                    <li>Environmental Sustainability</li>
                    <li>Economic Revolution</li>
                    <li>Culture & Tourism </li>
                  </ol>
                </div>
              </div>
              <div className="col-3">
                <div className="bottom">
                  <h2>Resource Centre</h2>
                  <ul>
                    <li>edostate.gov.ng</li>
                    <li>godwinobaseki.com</li>
                    <li>edojobs.edostate.gov.ng</li>
                    <li>eirs.org</li>
                    <li>edogis.org</li>
                    <li>Edojudiciary.org</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default Home;


const titles = [
  { name: 'Select item...', value: '' },
  { name: 'ALHAJI', value: 'ALH' },
  { name: 'ALHAJA', value: 'ALJ' },
  { name: 'ARCHITECT', value: 'ARCH' },
  { name: 'BARONESS', value: 'BARO' },
  { name: 'BARRISTER', value: 'BARR' },
  { name: 'CHIEF', value: 'CHIEF' },
  { name: 'COLONEL', value: 'COL' },
  { name: 'COMMANDER', value: 'COMDR' },
  { name: 'COMMRADE', value: 'COMM' },
  { name: 'CAPTAIN', value: 'CPTN' },
  { name: 'DEACON', value: 'DEAC' },
  { name: 'DOCTOR', value: 'DR' },
  { name: 'ELDER', value: 'ELDER' },
  { name: 'ENGINEER', value: 'ENGR' },
  { name: 'EVANGELIST', value: 'EVAN' },
  { name: 'EXCELLENCY', value: 'EXC' },
  { name: 'HIGH CHIEF', value: 'HIGH' },
  { name: 'HONOURABLE/HONBL', value: 'HON' },
  { name: 'HIS ROYAL HIGHNESS', value: 'HRH' },
  { name: 'JUSTICE', value: 'JUST' },
  { name: 'LADY', value: 'LADY' },
  { name: 'LT.COMMANDER', value: 'LCOM' },
  { name: 'LORD', value: 'LORD' },
  { name: 'MADAM', value: 'MADAM' },
  { name: 'MAJOR', value: 'MAJOR' },
  { name: 'MASTER', value: 'MAST' },
  { name: 'MAZI', value: 'MAZI' },
  { name: 'MESSERS', value: 'MES' },
  { name: 'MISS', value: 'MISS' },
  { name: 'MISTER', value: 'MIST' },
  { name: 'MR', value: 'MR' },
  { name: 'MRS.', value: 'MRS' },
  { name: 'MR & MRS', value: 'MM' },
  { name: 'MS', value: 'MS' },
  { name: 'OBA', value: 'OBA' },
  { name: 'OTUNBA', value: 'OTUN' },
  { name: 'PASTOR', value: 'PAST' },
  { name: 'PRINCE', value: 'PRINCE' },
  { name: 'PROFESSOR', value: 'PROF' },
  { name: 'REVEREND', value: 'REV' },
  { name: 'SHEIKH', value: 'SEKH' },
  { name: 'SENATOR', value: 'SENT' },
  { name: 'SIR', value: 'SIR' }
];

export default titles;

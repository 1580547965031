/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import { RiAddLine } from 'react-icons/ri';
import { BiEditAlt, BiFile } from 'react-icons/bi';
import { GiTrashCan } from 'react-icons/gi';

import SearchInput from '../inputs/SearchInput';
import LocationDetails from '../layouts/LocationDetails';
import ProfileForm from '../layouts/ProfileForm';
import ViewProfile from '../layouts/ViewProfile';
import notification from '../../utility/notification';
import { del, get } from '../../api-services/fetch';
import { formatDateTime } from '../../utility/dateTime';
import IsLoading from '../common/IsLoading';
import TablePaginationActions from '../common/TablePaginationActions';

const EmployeeProfile = ({ history }) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState([]);
  const [selectedRecordId, setSelectedRecordId] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [details, setDetails] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Employee Profile';

    getProfileList({});
  }, []);

  const getProfileList = async ({ query = null }) => {
    console.log(query);
    setIsLoading(true);
    setErrorMsg('');
    const res = await get('employeeProfile', { query }, null, true);

    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        setDetails(data.data);
        if (data.data.length < 1) {
          if (query) {
            setErrorMsg('Employee Profile not found');
          } else {
            setErrorMsg('No Employee Profile yet');
          }
        }
      } else {
        setErrorMsg(data.message);
      }
    } else {
      setErrorMsg('Something has gone wrong. Please, try again.');
    }
    setIsLoading(false);
  };

  const showEditForm = (id) => {
    const selected = details.filter((item) => id === item.userId);

    if (selected.length > 0) {
      setSelectedRecord(selected[0]);
      setShowFormModal(true);
    }
  };

  const showProfileForm = (id) => {
    const selected = details.filter((item) => id === item.staffId);
    console.log(details);
    if (selected.length > 0) {
      setSelectedRecordId(id);
      setShowProfileModal(true);
    }
  };

  const closeModal = () => {
    setSelectedRecord([]);
    setShowFormModal(false);
  };

  const closeProfileModal = () => {
    setShowProfileModal(false);
  };

  const deleteItem = async (staffId, name) => {
    const confirmAction = confirm(`Click OK to confirm the deletion of "${name}"`);
    if (!confirmAction) return;
    setIsDeleting(true);
    const res = await del('employeeProfile', staffId, true);

    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        notification({
          title: 'Successful Employee Profile Deletion',
          message: data.message,
          type: 'success'
        });
        const others = details.filter((item) => staffId !== item.staffId);
        setDetails(others);
      } else {
        notification({
          title: 'Employee Profile Deletion Error',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Network Error',
        message:
          'Something went wrong while deleting an Employee Profile. Please, try again later.',
        type: 'danger'
      });
    }
    setIsDeleting(false);
  };

  const handleSearch = async (e) => {
    const { name, value } = e.target;

    if (name === 'searchText') {
      setSearchText(value);
      console.log(value);
      await getProfileList({ query: value });
    }
  };

  const updateList = (newItem, isUpdate) => {
    if (isUpdate) {
      const newList = details.map((item) => (item.userId === newItem.userId ? newItem : item));
      setDetails(newList);
    } else {
      const newList = [...details];
      newList.unshift(newItem);
      setDetails(newList);
    }
  };

  return (
    <div className="w-100">
      <div className="w-100 flex flex-end">
        <LocationDetails />
      </div>
      <div className="w-100 flex space-between m-t-20 flex-v-center">
        <h2>Staff Onboarding</h2>
        <SearchInput
          name="searchText"
          value={searchText}
          onChange={handleSearch}
          placeholder="Search list..."
        />
      </div>
      <div className="table-view m-t-10">
        <div className="table-header">
          <div className="table-row">
            <div className="th">
              <p>View</p>
            </div>
            <div className="th">
              <p>Staff List</p>
            </div>
            <div className="th">
              <p>Last Updated</p>
            </div>
            <div className="th">
              <p>Last Updated By</p>
            </div>
            <div className="th">
              <button
                className="add-button pointer"
                type="button"
                onClick={() => setShowFormModal(true)}>
                <RiAddLine />
                Add Profile
              </button>
            </div>
          </div>
        </div>
        <div className="table-body">
          {!(isLoading || errorMsg) &&
            details &&
            (rowsPerPage > 0
              ? details.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : details
            ).map((item) => (
              <div className="table-row" key={item.userId}>
                <div className="td actions">
                  <button
                    type="button"
                    className="pointer"
                    onClick={() => showProfileForm(item.staffId)}>
                    <BiFile />
                  </button>
                </div>
                <div className="td">{`${item.firstName} ${item.lastName}`}</div>
                <div className="td">{formatDateTime(item.updatedAt || item.createdAt)}</div>
                <div className="td">{item.updatedBy || item.createdBy}</div>
                <div className="td actions">
                  <button
                    type="button"
                    className="m-l-10 pointer"
                    onClick={() => showEditForm(item.userId)}>
                    <BiEditAlt />
                  </button>
                  {/* <button
                    type="button"
                    className="m-l-10 del pointer"
                    onClick={() => deleteItem(item.staffId, item.firstName)}>
                    <GiTrashCan />
                  </button> */}
                </div>
              </div>
            ))}
        </div>
      </div>
      {isLoading && <div className="w-100 center-text p-20">Loading...</div>}
      {errorMsg && <div className="w-100 center-text p-20">{errorMsg}</div>}
      {(isLoading || isDeleting) && <IsLoading message={isDeleting ? 'Deleting...' : null} />}
      {showFormModal && (
        <ProfileForm details={selectedRecord} updateList={updateList} closeModal={closeModal} />
      )}
      {showProfileModal && (
        <ViewProfile staffId={selectedRecordId} closeModal={closeProfileModal} />
      )}
      <TablePaginationActions
            page={page}
            rowsPerPage={rowsPerPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            count={details.length}
            changeRowsPerPage={setRowsPerPage}
          />
    </div>
  );
};

export default EmployeeProfile;

import React, { useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';

import Loader from '../../assets/svg/loading.svg';
import { toggleScroll } from '../../utility/general';
import AutoComplete from '../inputs/AutoComplete';
import { get, patch, post } from '../../api-services/fetch';
import notification from '../../utility/notification';
import { decodeToken } from '../../utility/auth';

const OrganogramForm = ({ closeModal, updateList, details }) => {
  const [userId, setUserId] = useState([]);
  const [userName, setUserName] = useState([]);
  const [firstOfficerId, setFirstOfficerId] = useState(0);
  const [firstOfficerName, setFirstOfficerName] = useState('');
  const [secondOfficerId, setSecondOfficerId] = useState(0);
  const [secondOfficerName, setSecondOfficerName] = useState('');
  const [fullName, setFullName] = useState('')
  const [formType, setFormType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);

  useEffect(() => {
    console.log(details)
    toggleScroll();
    getEmployeeList();
    if (details && details.id) {
      setFormType('edit');
      setUserId(details.userId);
      setFirstOfficerId(details.firstOfficerId);
      setSecondOfficerId(details.secondOfficerId);
      setFirstOfficerName(details.firstOfficerName)
      setSecondOfficerName(details.secondOfficerName)
      setUserName(details.username)
      setFullName(decodeToken("fn").fn + " " + decodeToken("ln").ln)
    } else {
      setFormType('create');
    }

    return () => setTimeout(() => {
      toggleScroll();
    }, 0);
  }, [details]);

  const validateForm = () => {
    console.log(userId, firstOfficerId, secondOfficerId);
    // if (!userId) return 'Please, select at least an employee';
    if (!firstOfficerId) return 'Please, select first supervisor office for the employee';
    if (formType === 'create' && userId.includes(firstOfficerId)) return 'An employee cannot supervise him/herself';
    if (!secondOfficerId) return 'Please, select second supervisor office for the employee';
    if (formType === 'create' && userId.includes(secondOfficerId)) return 'An employee cannot supervise him/herself';
    if (firstOfficerId === secondOfficerId) return 'First and second supervisor must be different persons';
    return null;
  };


  const changeUserId =(value, name)=>{
    console.log(value)
    console.log(name)
    console.log(employeeList)
    let currentItem = value[value?.length - 1]
    console.log(currentItem)
    if(employeeList.filter((item)=>{
      return item.value === currentItem
    })){
      setUserId(value);
    }
    else{
      return null;
    }
   console.log(employeeList.includes(currentItem)) 
    
  }
  const submitForm = async () => {
    const body = {
      userId,
      firstOfficerId,
      secondOfficerId
    };
    const error = validateForm();

    if (error) {
      notification({
        title: 'Invalid Entry',
        message: error,
        type: 'danger'
      });
      return;
    }

    setIsLoading(true);
    const res = formType === 'create'
      ? await post('organogram', body, true) : await patch('organogram', body, details.id, true);
    console.log(res);
    setIsLoading(false);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        notification({
          title: `Successful Organogram ${formType === 'create' ? 'Creation' : 'Update'}`,
          message: data.message,
          type: 'success'
        });
        updateList(data.data, formType === 'edit');
        if (formType === 'edit') {
          closeModal();
          return;
          
        }
       else if (formType === 'create') {
        setUserId('');
        setFirstOfficerId('');
        setSecondOfficerId(0);
          closeModal();
          return;
          
        }
        setUserId('');
        setFirstOfficerId('');
        setSecondOfficerId(0);
      } else {
        notification({
          title: `Organogram ${formType === 'create' ? 'Creation' : 'Update'} Error`,
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Network Error',
        message: `Something went wrong while ${formType === 'create' ? 'creating' : 'updating'} an Organogram. Please, try again later.`,
        type: 'danger'
      });
    }
  };

  const getEmployeeList = async () => {
    const res = await get('user', null, null, true);
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      console.log(data)
      
      if (data.code === 1) {
        const roleList = data.data
          .map((item) => ({ name: item.displayName, value: item.userId }));
        console.log(roleList);
        details.firstOfficerId && details.firstOfficerName && roleList.unshift({name:details?.firstOfficerName, value:details?.firstOfficerId})
        details.secondOfficerId && details.secondOfficerName && roleList.unshift({name:details?.secondOfficerName, value:details?.secondOfficerId})
        details.userId && details.username && roleList.unshift({name:details?.username, value:details?.userId})
        setEmployeeList(roleList);
      } else {
        notification({
          title: 'Error getting List of Employees',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Error getting List of Employees',
        message: 'Something has gone wrong. Please, try again.',
        type: 'danger'
      });
    }
  };

  const fetchEmployeeList = async (searchText) => {
    const res = await get('user', {query:searchText}, null, true);
    console.log(res);
    console.log(res.data.data);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        const roleList = data.data.filter((data) => {return !data?.displayName.includes(fullName)})
          .map((item) => ({ name: item.displayName, value: item.userId }));
        console.log(roleList);
        details.firstOfficerId && details.firstOfficerName && roleList.unshift({name:details?.firstOfficerName, value:details?.firstOfficerId})
        details.secondOfficerId && details.secondOfficerName && roleList.unshift({name:details?.secondOfficerName, value:details?.secondOfficerId})
        details.userId && details.username && roleList.unshift({name:details?.username, value:details?.userId})
        setEmployeeList(roleList);
      } else {
        notification({
          title: 'Error getting List of Employees',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Error getting List of Employees',
        message: 'Something has gone wrong. Please, try again.',
        type: 'danger'
      });
    }
  };

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box max-w-400">
        <div className="content">
          <AutoComplete
            label="Select Staff (You can select multiple names)"
            multiple
            options={employeeList || []}
            value={details.userId}
            onChange={(value,name) => changeUserId(value, name)}
            disabled={formType === 'edit'}
            getOptions={(e) => {fetchEmployeeList(e)
            setEmployeeList([])
            }}
            className="w-100 m-b-10"
          />
          <AutoComplete
            label="Select name of Reporting Officer"
            options={employeeList || []}
            value={details.firstOfficerId}
            onChange={(value) => setFirstOfficerId(value)}
            getOptions={(e) => {fetchEmployeeList(e)
            setEmployeeList([])
            }}
            className="w-100 m-b-10"
          />
          <AutoComplete
            label="Select Second Line Officer (Counter Signing Officer)"
            options={employeeList || []}
            value={details.secondOfficerId}
            onChange={(value) => setSecondOfficerId(value)}
            getOptions={(e) => {fetchEmployeeList(e)
            setEmployeeList([])
            }}
            className="w-100 m-b-10"
          />
          <button
            type="button"
            className="btn btn-large w-100"
            onClick={submitForm}
            disabled={isLoading}
          >
            {!isLoading && `${formType === 'create' ? 'Create' : 'Update'} Organogram`}
            {isLoading && `${formType === 'create' ? 'Creating' : 'Updating'} Organogram...`}
            {isLoading && <img src={Loader} className="btn-loading" alt="Loading..." />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrganogramForm;

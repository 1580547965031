import React, { useState, useEffect } from 'react';
import { IoMdClose } from 'react-icons/io';
import { toggleScroll } from '../../utility/general';
// import MultiSelectInput from '../inputs/MultiSelectInput';
import Select from 'react-select-search';
import Loader from '../../assets/svg/loading.svg';
import SelectInput from '../inputs/SelectInput';
import TextInput from '../inputs/TextInput';
import notification from '../../utility/notification';
import { get, patch, post } from '../../api-services/fetch';
import AutoComplete from '../inputs/AutoComplete';

const ProfileForm = ({ closeModal, updateList, details }) => {
  const [staffId, setStaffId] = useState('');
  const [firstName, setFirstname] = useState('');
  const [lastName, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [mda, setMda] = useState('');
  const [mdas, setMdas] = useState([]);
  const [role, setRole] = useState('');
  const [roles, setRoles] = useState([]);
  const [gender, setGender] = useState('');
  const [formType, setFormType] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    toggleScroll();
    if (details && details.userId) {
      setFormType('edit');
      setStaffId(details.staffId || '');
      setFirstname(details.firstName || '');
      setLastname(details.lastName || '');
      setEmail(details.email || '');
      setMda(details.mda || '');
      setRole((details.role && details.role[0]) || '');
      setGender(details.gender || '');
    } else {
      setFormType('create');
    }

    getRoles();
    getMdas();

    return () => setTimeout(() => {
      toggleScroll();
    }, 0);
  }, [details]);

  const getMdas = async () => {
    const res = await get('mda', null, null, true);
    console.log(res);
    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        const mdaList = data.data.map(({ name, id }) => ({ name, value: id }));
        mdaList.unshift({ name: 'Select item...', value: '' });
        setMdas(mdaList);
      } else {
        notification({
          title: 'Error getting List of MDAs',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Error getting List of MDAs',
        message: 'Something has gone wrong. Please, try again.',
        type: 'danger'
      });
    }
  };

  const fetchMdas = async (searchText) => {
    const res = await get('mda', {query:searchText}, null, true);
    console.log(res);
    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        const mdaList = data.data.map(({ name, id }) => ({ name, value: id }));
        mdaList.unshift({ name: 'Select item...', value: '' });
        setMdas(mdaList);
      } else {
        notification({
          title: 'Error getting List of MDAs',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Error getting List of MDAs',
        message: 'Something has gone wrong. Please, try again.',
        type: 'danger'
      });
    }
  };

  const getRoles = async () => {
    const res = await get('role', null, null, true);
    console.log(res);
    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        const roleList = data.data.map(({ name, id }) => ({ name, value: id }));
        roleList.unshift({ name: 'Select item...', value: '' });
        setRoles(roleList);
      } else {
        notification({
          title: 'Error getting List of Roles',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Error getting List of Roles',
        message: 'Something has gone wrong. Please, try again.',
        type: 'danger'
      });
    }
  };

  const fetchRoles = async (searchText) => {
    const res = await get('role', {query:searchText} , null, true);
    console.log(res);
    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        const roleList = data.data.map(({ name, id }) => ({ name, value: id }));
        roleList.unshift({ name: 'Select item...', value: '' });
        setRoles(roleList);
      } else {
        notification({
          title: 'Error getting List of Roles',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Error getting List of Roles',
        message: 'Something has gone wrong. Please, try again.',
        type: 'danger'
      });
    }
  };

  const submitForm = async () => {
    const body = {
      staffId,
      firstName,
      lastName,
      email,
      mda: Number(mda),
      role: [Number(role)],
      gender
    };
    const error = validateForm(body);

    if (error) {
      notification({
        title: 'Invalid Entry',
        message: error,
        type: 'danger'
      });
      return;
    }

    setIsLoading(true);
    const res = formType === 'create'
      ? await post('employeeProfile', body, true)
      : await patch('employeeProfile', { ...body, userId: details.userId }, null, true);
    console.log(res);
    setIsLoading(false);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        notification({
          title: `Successful Profile ${formType === 'create' ? 'Creation' : 'Update'}`,
          message: data.message,
          type: 'success'
        });
        updateList(data.data, formType === 'edit');
        if (formType === 'edit') {
          closeModal();
          return;
        }
        setStaffId('');
        setFirstname('');
        setLastname('');
        setEmail('');
        setMda('');
        setRole('');
        setGender('');
      } else {
        notification({
          title: `Profile ${formType === 'create' ? 'Creation' : 'Update'} Error`,
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Network Error',
        message: `Something went wrong while ${formType === 'create' ? 'creating' : 'updating'} a Profile. Please, try again later.`,
        type: 'danger'
      });
    }
  };

  const validateForm = () => {
    if (!staffId) return 'Please, enter Staff ID of the employee';
    if (!firstName) return 'Please, enter first name of the employee';
    if (firstName && !firstName.match(/^[A-Za-z]+$/)) return 'First name should contain only alphabets';
    if (!lastName) return 'Please, enter last name of the employee';
    if (lastName && !lastName.match(/^[A-Za-z]+$/)) return 'Last name should contain only alphabets';
    if (!email) return 'Please, enter e-mail address of the employee';
    if (email && !/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(email)) {
      return 'Please enter a valid email';
    }
    if (!mda) return 'Please, select an MDA for the employee';
    // if (!role) return 'Please, select at least one Role for the employee';
    if (!gender) return 'Please, select gender of the employee';
    return null;
  };

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box max-w-400">
        <div className="content">
          <TextInput
            name="staffId"
            value={staffId}
            onChange={(e) => setStaffId(e.target.value)}
            className="w-100 m-b-10"
            label="Staff ID*"
          />
          <TextInput
            name="firstName"
            value={firstName}
            onChange={(e) => setFirstname(e.target.value)}
            className="w-100 m-b-10"
            label="First Name*"
          />
          <TextInput
            name="lastName"
            value={lastName}
            onChange={(e) => setLastname(e.target.value)}
            className="w-100 m-b-10"
            label="Last Name*"
          />
          <TextInput
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-100 m-b-10"
            label="Email Address*"
          />
          <AutoComplete
            name="mda"
            search
            value={mda}
            onChange={(value) => setMda(value)}
            className="w-100 m-b-10"
            label="Assign MDA*"
            getOptions={(e) => fetchMdas(e)}
            options={mdas || []}
          />
          <div className="row">
            <div className="col-8 no-margin">
              <AutoComplete
                name="role"
                value={role}
                className="w-100 m-b-10"
                label="Assign Role(s)"
                onChange={(value) => setRole(value)}
                getOptions={(e) => fetchRoles(e)}
                options={roles || []}
              />
            </div>
            <div className="col-4 no-margin">
              <SelectInput
                name="gender"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                className="w-100 m-b-10"
                label="Gender*"
                
                options={['', 'Male', 'Female']}
              />
            </div>
          </div>
          <button
            type="button"
            className="btn btn-large w-100"
            onClick={submitForm}
            disabled={isLoading}
          >
            {!isLoading && `${formType === 'create' ? 'Create' : 'Update'} Employee Profile`}
            {isLoading && `${formType === 'create' ? 'Creating' : 'Updating'} Employee Profile...`}
            {isLoading && <img src={Loader} className="btn-loading" alt="Loading..." />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileForm;

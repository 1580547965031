/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-bracket-location */
import React, { useState, useEffect } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';

import TextInput from '../../inputs/TextInput';
import SelectInput from '../../inputs/SelectInput';
import FormDateInput from '../../inputs/FormDateInput';
// import MultiSelectInput from '../../inputs/MultiSelectInput';
import bankNames from '../../../dataFiles/bankNames';
import employeeLevels from '../../../dataFiles/employeeLevels';
import employeeSteps from '../../../dataFiles/cadreSteps';

import notification from '../../../utility/notification';
import { get } from '../../../api-services/fetch';
import AutoComplete from '../../inputs/AutoComplete';

const OfficialDetails = ({
  roleName,
  staffId,
  officialEmail,
  currentLevel,
  currentStep,
  mda,
  role,
  salaryAccountNumber,
  salaryDomiciledBank,
  salaryGrade,
  stepOfFirstApp,
  fileNumber,
  employeeNumber,
  dateFirstAppointment,
  cadreFirstAppointment,
  dateConfirmAppointment,
  datePresentPosition,
  bvn,
  currentCadre,
  nin,
  searchEmployee,
  selected,
  noValidRecord,
  active
}) => {
  const [mdas, setMdas] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    // console.log(roleName)
    getRoles();
    getMdas();
    
  }, [currentCadre.value, cadreFirstAppointment.value, role.value]);

  

  const getRoles = async () => {
    const res = await get('role', null, null, true);
    console.log(res);
    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        const roleList = data.data.map(({ name, id }) => ({ name, value: id }));
        roleList.push({name:currentCadre.value, value:currentCadre.value})
        roleList.push({name:cadreFirstAppointment.value, value:cadreFirstAppointment.value})
        roleList.push({name:roleName, value:role.value})
        roleList.unshift({ name: '', value: '' });
        setRoles(roleList);
      } else {
        notification({
          title: 'Error getting List of Roles',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Error getting List of Roles',
        message: 'Something has gone wrong. Please, try again.',
        type: 'danger'
      });
    }
  };

  

  const fetchJustRoles = async (searchText) => {
    const res = await get('role', {query:searchText}, null, true);
    console.log(res);
    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        const roleList = data.data.map(({ name, id }) => ({ name, value: id }));
        roleList.unshift({ name: roleName, value: '' });
        
        setRoles(roleList);
      } else {
        notification({
          title: 'Error getting List of Roles',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Error getting List of Roles',
        message: 'Something has gone wrong. Please, try again.',
        type: 'danger'
      });
    }
  };

  const fetchRoles = async (searchText) => {
    const res = await get('role', {query:searchText}, null, true);
    console.log(res);
    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        const roleList = data.data.map(({ name, id }) => ({ name, value: name }));
        roleList.unshift({ name: roleName, value: '' });
        setRoles(roleList);
      } else {
        notification({
          title: 'Error getting List of Roles',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Error getting List of Roles',
        message: 'Something has gone wrong. Please, try again.',
        type: 'danger'
      });
    }
  };

  let selectedOptions = [{name:"Selected", value: "Selected"}, {name:"Not Selected", value: "Not Selected"}]; 
  let activeOptions = [{name:"Active", value: "Active"}, {name:"Retired", value: "Retired"}];

  const getMdas = async () => {
    const res = await get('mda', null, null, true);
    console.log(res);
    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        const mdaList = data.data.map(({ name, id }) => ({ name, value: id }));
        mdaList.unshift({ name: 'Select item...', value: '' });
        setMdas(mdaList);
      } else {
        notification({
          title: 'Error getting List of MDAs',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Error getting List of MDAs',
        message: 'Something has gone wrong. Please, try again.',
        type: 'danger'
      });
    }
  };

  const fetchMdas = async (searchText) => {
    const res = await get('mda',{query:searchText}, null, true);
    console.log(res);
    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        const mdaList = data.data.map(({ name, id }) => ({ name, value: id }));
        mdaList.unshift({ name: 'Select item...', value: '' });
        setMdas(mdaList);
      } else {
        notification({
          title: 'Error getting List of MDAs',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Error getting List of MDAs',
        message: 'Something has gone wrong. Please, try again.',
        type: 'danger'
      });
    }
  };

  return (
    !noValidRecord && (
      <div className="w-100 official-details">
        <div className="row">
          <div className="col-6 no-margin">
            <div className="flex flex-h-center flex-v-center">
              <TextInput
                className="w-100 m-b-10"
                label="Staff ID/ Email/NIN"
                value={staffId.value || ''}
                onChange={staffId.onChange}
                disabled={!noValidRecord}
              />
            </div>
          </div>
          {!noValidRecord && (
            <div className="col-6 no-margin">
              <AutoComplete
                className="w-100 m-b-10"
                label="Current Cadre"
                value={Number(currentCadre.value) || currentCadre.value || currentCadre || ''}
                getOptions={(e) => fetchRoles(e)}
                options={roles || []}
                onChange={
                  currentCadre.onChange
              
                }
              />
            </div>
          )}
        </div>
        {!noValidRecord && (
          <div className="row">
            <div className="col-6 no-margin">
              <AutoComplete
                value={role.value || ''}
                className="w-100 m-b-10"
                name="role"
                label="Assign Role(s)"
                onChange={ 
                  role.onChange
                }
                getOptions={(e) => fetchJustRoles(e)}
                options={roles || []}
              />
            </div>
            <div className="col-3 no-margin">
              <SelectInput
                className="w-100 m-b-10"
                label="Current Level"
                value={currentLevel.value || ''}
                onChange={currentLevel.onChange}
                options={employeeLevels || []}
              />
            </div>
            <div className="col-3 no-margin">
              <SelectInput
                className="w-100 m-b-10"
                label="Current Step"
                value={currentStep.value || ''}
                onChange={currentStep.onChange}
                options={employeeSteps || []}
              />
            </div>
          </div>
        )}
        {!noValidRecord && (
          <div className="row">
            <div className="col-6 no-margin">
              <AutoComplete
                className="w-100 m-b-10"
                label="Cadre on First Appointment"
                value={Number(cadreFirstAppointment.value) || cadreFirstAppointment.value || cadreFirstAppointment || ''}
                onChange={cadreFirstAppointment.onChange}
                getOptions={(e) => fetchRoles(e)}
                options={roles || []}
              />
            </div>
            <div className="col-6 no-margin">
              <FormDateInput
                className="w-100 m-b-10"
                label="Date of First Appointment"
                value={dateFirstAppointment.value || ''}
                onChange={dateFirstAppointment.onChange}
              />
            </div>
          </div>
        )}
        {!noValidRecord && (
          <div className="row">
            <div className="col-3 no-margin">
              <SelectInput
                value={salaryGrade.value || ''}
                className="w-100 m-b-10"
                name="salary Grade"
                label="Salary Grade Level of First Appointment"
                onChange={salaryGrade.onChange}
                options={employeeLevels || []}
              />
            </div>
            <div className="col-3 no-margin">
              <SelectInput
                value={stepOfFirstApp.value || ''}
                className="w-100 m-b-10"
                name="Step"
                label="Step"
                onChange={stepOfFirstApp.onChange}
                options={employeeSteps || []}
              />
            </div>
            <div className="col-3 no-margin">
              <TextInput
                value={employeeNumber.value || ''}
                className="w-100 m-b-10"
                name="Employee Number"
                label="Employee Number"
                onChange={employeeNumber.onChange}
              />
            </div>
            <div className="col-3 no-margin">
              <TextInput
                value={fileNumber.value || ''}
                className="w-100 m-b-10"
                name="File Number"
                label="File Number"
                onChange={fileNumber.onChange}
              />
            </div>
          </div>
        )}
        {!noValidRecord && (
          <div className="row">
            <div className="col-3 no-margin">
              <FormDateInput
                className="w-100 m-b-10"
                label="Date of Confirmation of Appointment"
                value={dateConfirmAppointment.value || ''}
                onChange={dateConfirmAppointment.onChange}
              />
            </div>
            <div className="col-3 no-margin">
              <SelectInput
                className="w-100 m-b-10"
                label="Active"
                value={active.value || ''}
                onChange={active.onChange}
                options={activeOptions}
              />
            </div>





            
            <div className="col-3 no-margin">
              <FormDateInput
                className="w-100 m-b-10"
                label="Date of Present Position"
                value={datePresentPosition.value || ''}
                onChange={datePresentPosition.onChange}
              />
            </div>
            <div className="col-3 no-margin">
              <SelectInput
                className="w-100 m-b-10"
                label="Selected"
                value={selected?.value}
                onChange={selected?.onChange}
                options={selectedOptions}
              />
            </div>
  
          </div>
        )}
        {!noValidRecord && (
          <div className="row">
            <div className="col-6 no-margin">
              <AutoComplete
                className="w-100 m-b-10"
                label="Assigned MDA"
                value={mda.value || ''}
                options={mdas}
                getOptions={(e) => fetchMdas(e)}
                onChange={mda.onChange}
              />
            </div>
            <div className="col-6 no-margin">
              <TextInput
                className="w-100 m-b-10"
                label="Official Email "
                value={officialEmail.value || ''}
                onChange={officialEmail.onChange}
              />
            </div>
          </div>
        )}
        {!noValidRecord && (
          <div className="row">
            <div className="col-6 no-margin">
              <TextInput
                className="w-100 m-b-10"
                label="Salary Account Number"
                value={salaryAccountNumber.value || ''}
                onChange={salaryAccountNumber.onChange}
              />
            </div>
            <div className="col-6 no-margin">
              <TextInput
                className="w-100 m-b-10"
                label="NIN"
                value={nin.value || ''}
                onChange={nin.onChange}
              />
            </div>
          </div>
        )}
        {!noValidRecord && (
          <div className="row">
            <div className="col-6 no-margin">
              <SelectInput
                className="w-100 m-b-10"
                label="Salary Domiciled Bank"
                value={salaryDomiciledBank.value || ''}
                onChange={salaryDomiciledBank.onChange}
                options={bankNames || []}
              />
            </div>
            <div className="col-6 no-margin">
              <TextInput
                className="w-100 m-b-10"
                label="BVN"
                value={bvn.value || ''}
                onChange={bvn.onChange}
              />
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default OfficialDetails;

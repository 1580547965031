import React, { useEffect, useState } from 'react';

import TextInput from '../../inputs/TextInput';
import SelectInput from '../../inputs/SelectInput';

import titles from '../../../dataFiles/titles';
import maritalStatusList from '../../../dataFiles/maritalStatusList';
import { get } from '../../../api-services/fetch';
import notification from '../../../utility/notification';
import { formatDateforPicker } from '../../../utility/dateTime';

const PersonalDetails = ({
  staffId,
  title,
  lastName,
  firstName,
  middleName,
  dob,
  nationality,
  placeOfBirth,
  gender,
  religion,
  maritalStatus,
  state,
  motherMaidenName,
  weddingAnniversary,
  mobile,
  personalEmail,
  lGOrigin,
  homeTown,
  permHomeAddress,
  residentialAddress,
  languageSpoken,
  rin
}) => {
  const [nationalities, setNationalities] = useState([]);
  const [states, setStates] = useState([]);
  const [religions, setReligions] = useState([]);

  useEffect(() => {
    const getnationalities = async () => {
      const res = await get('global/api/nationality', null, null, true);
      console.log(res);
      if (res && res.status === 200) {
        const { data } = res;
        if (data.code === 1) {
          const nationalityList = data.data.map(({ name, id }) => ({ name, value: id }));
          nationalityList.unshift({ name: 'Select item...', value: '' });
          setNationalities(nationalityList);
        } else {
          notification({
            title: 'Error getting List of Nationalities',
            message: data.message,
            type: 'danger'
          });
        }
      } else {
        notification({
          title: 'Error getting List of Nationalities',
          message: 'Something has gone wrong. Please, try again.',
          type: 'danger'
        });
      }
    };

    const getstates = async () => {
      const res = await get('global/api/state', null, null, true);
      console.log(res);
      if (res && res.status === 200) {
        const { data } = res;
        if (data.code === 1) {
          const list = data.data.map(({ name, id }) => ({ name, value: id }));
          list.unshift({ name: 'Select item...', value: '' });
          setStates(list);
        } else {
          notification({
            title: 'Error getting List of States',
            message: data.message,
            type: 'danger'
          });
        }
      } else {
        notification({
          title: 'Error getting List of States',
          message: 'Something has gone wrong. Please, try again.',
          type: 'danger'
        });
      }
    };

    const getReligions = async () => {
      const res = await get('global/api/religion', null, null, true);
      console.log(res);
      if (res && res.status === 200) {
        const { data } = res;
        if (data.code === 1) {
          const list = data.data.map(({ name, id }) => ({ name, value: id }));
          list.unshift({ name: 'Select item...', value: '' });
          setReligions(list);
        } else {
          notification({
            title: 'Error getting List of Nationalities',
            message: data.message,
            type: 'danger'
          });
        }
      } else {
        notification({
          title: 'Error getting List of Nationalities',
          message: 'Something has gone wrong. Please, try again.',
          type: 'danger'
        });
      }
    };

    getnationalities();
    getstates();
    getReligions();
  }, []);

  return (
    <div className="w-100 official-details">
      <div className="row">
        <div className="col-4 no-margin">
          <TextInput
            className="w-100 m-b-10"
            label="Staff ID"
            value={staffId.value || ''}
            onChange={staffId.onChange}
            disabled
          />
        </div>
        <div className="col-4 no-margin">
          <SelectInput
            options={titles || []}
            className="w-100 m-b-10"
            label="Title"
            value={title.value || ''}
            onChange={title.onChange}
          />
        </div>
        <div className="col-4 no-margin">
          <TextInput
            className="w-100 m-b-10"
            label="Mobile Number"
            value={mobile.value || ''}
            onChange={mobile.onChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4 no-margin">
          <TextInput
            className="w-100 m-b-10"
            label="Personal Email"
            value={personalEmail.value || ''}
            onChange={personalEmail.onChange}
          />
        </div>

        <div className="col-4 no-margin">
          <TextInput
            className="w-100 m-b-10"
            label="Home Town"
            value={homeTown.value || ''}
            onChange={homeTown.onChange}
          />
        </div>
       <div className="col-4 no-margin">
          <TextInput
            className="w-100 m-b-10"
            label="RIN"
            value={rin?.value || ''}
            onChange={rin?.onChange}
          />
        </div> 
      </div>
      <div className="row">
        <div className="col-4 no-margin">
          <TextInput
            className="w-100 m-b-10"
            label="Permanent Home Address"
            value={permHomeAddress.value || ''}
            onChange={permHomeAddress.onChange}
          />
        </div>
        <div className="col-4 no-margin">
          <TextInput
            className="w-100 m-b-10"
            label="Residential Address"
            value={residentialAddress.value || ''}
            onChange={residentialAddress.onChange}
          />
        </div>
        <div className="col-4 no-margin">
          <TextInput
            className="w-100 m-b-10"
            label="Languages Spoken"
            value={languageSpoken.value || ''}
            onChange={languageSpoken.onChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4 no-margin">
          <TextInput
            className="w-100 m-b-10"
            label="Surname"
            value={lastName.value || ''}
            onChange={lastName.onChange}
          />
        </div>
        <div className="col-4 no-margin">
          <TextInput
            className="w-100 m-b-10"
            label="First Name"
            value={firstName.value || ''}
            onChange={firstName.onChange}
          />
        </div>
        <div className="col-4 no-margin">
          <TextInput
            className="w-100 m-b-10"
            label="Middle Name"
            value={middleName.value || ''}
            onChange={middleName.onChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4 no-margin">
          <TextInput
            type="date"
            className="w-100 m-b-10"
            label="Date of Birth"
            maxDate={formatDateforPicker(new Date())}
            value={dob.value || ''}
            onChange={dob.onChange}
          />
        </div>
        <div className="col-4 no-margin">
          <SelectInput
            options={['', 'Male', 'Female']}
            className="w-100 m-b-10"
            label="Gender"
            value={gender.value || ''}
            onChange={gender.onChange}
          />
        </div>
        <div className="col-4 no-margin">
          <SelectInput
            options={maritalStatusList || []}
            className="w-100 m-b-10"
            label="Marital Status"
            value={maritalStatus.value || ''}
            onChange={maritalStatus.onChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4 no-margin">
          <TextInput
            className="w-100 m-b-10"
            label="Place of Birth"
            value={placeOfBirth.value || ''}
            onChange={placeOfBirth.onChange}
          />
        </div>
        <div className="col-4 no-margin">
          <SelectInput
            options={nationalities || []}
            className="w-100 m-b-10"
            label="Nationality"
            value={nationality.value || ''}
            onChange={nationality.onChange}
          />
        </div>
        <div className="col-4 no-margin">
          <SelectInput
            options={states || []}
            className="w-100 m-b-10"
            label="State of Origin"
            value={state.value || ''}
            onChange={state.onChange}
            disabled={nationality.value.toString() !== '19'}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4 no-margin">
          <SelectInput
            options={religions || []}
            className="w-100 m-b-10"
            label="Religion"
            value={religion.value || ''}
            onChange={religion.onChange}
          />
        </div>
        <div className="col-4 no-margin">
          <TextInput
            className="w-100 m-b-10"
            label="Mother's Maiden Name"
            value={motherMaidenName.value || ''}
            onChange={motherMaidenName.onChange}
          />
        </div>
        <div className="col-4 no-margin">
          <TextInput
            type="date"
            className="w-100 m-b-10"
            label="Wedding Anniversary"
            maxDate={formatDateforPicker(new Date())}
            value={weddingAnniversary.value || ''}
            onChange={weddingAnniversary.onChange}
            disabled={maritalStatus.value !== 'Married'}
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;

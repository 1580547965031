import React, { useState, useEffect } from 'react';
import { IoMdClose } from 'react-icons/io';
import { get, patch, post } from '../../api-services/fetch';

// import { patch, post } from '../../api-services/fetch';
import { toggleScroll } from '../../utility/general';
import Loader from '../../assets/svg/loading.svg';
import AutoComplete from '../inputs/AutoComplete';

import TextareaInput from '../inputs/TextareaInput';
import TextInput from '../inputs/TextInput';
import notification from '../../utility/notification';
import { formatDateforPicker, getDaysFromDate } from '../../utility/dateTime';
import SelectInput from '../inputs/SelectInput';


const accessRights = [
    {
        name: "Select Access...",
        value: null
    },
    {
        name: "Grant",
        value: true
    },
    {
        name: "Revoke",
        value: false
    }
]

const AccessForm = ({ details, updateList, closeModal }) => {
    const [name, setName] = useState('');
    const [mandate, setMandate] = useState('');
    const [dateOfEstablishment, setDateOfEstablishment] = useState('');
    const [address, setAddress] = useState('');
    const [formType, setFormType] = useState('');
    const [permanentSecretaryId, setpermanentSecretaryId] = useState();
    const [permanentSecretary, setpermanentSecretary] = useState(0);
    const [employeeList, setEmployeeList] = useState([]);
    const [userId, setUserId] = useState([]);
    const [payload, setPayload] = useState({
        userId: 0,
        hasAccess: false,
        accessTypeId: 0
    })
    const [isLoading, setIsLoading] = useState(false);
    const [accessList, setAccessList] = useState([]);
    const [mdaList, setMdaList] = useState([]);


    useEffect(() => {

        getMda();


        getAccessList();
        toggleScroll();
        if (details && details.id) {

        } else {
            setFormType('create');
        }

        return () => setTimeout(() => {
            toggleScroll();
        }, 0);
    }, [details]);





    const getMda = async () => {
        const res = await get('mda', null, null, true);
        console.log(res);
        let listMDA = res?.data?.data?.map((data) => ({ name: data?.name, value: data?.id }))
        listMDA?.unshift({ name: "Select MDA", value: 0 })
        setMdaList(listMDA);
        console.log(res);
    }

    const getAccessList = async () => {
        setIsLoading(true);
        const res = await get('access', null);

        console.log(res);

        if (res && res.status === 200) {
            const { data } = res;
            if (data.code === 1) {
                let accessTypesList = data.data.map((data) => ({ name: data?.accessType, value: data.id }))
                accessTypesList?.unshift({ name: "Select Access Category...", value: 0 })
                setAccessList(accessTypesList);
                console.log(data.data);
            }
        }
        setIsLoading(false);
    };



    const getEmployeeList = async (id) => {
        const res = await get(`services/api/Employees/employees_by_mda?mdaid=${id}`, null, null, true);
        console.log(res);

        if (res && res.status === 200) {
            const roleList = res?.data
                ?.map((item) => ({ name: `${item.name} `, value: item.id }));
            console.log(roleList);
            setEmployeeList(roleList);
        } else {
            notification({
                title: 'Error getting List of Employees',
                message: 'Something has gone wrong. Please, try again.',
                type: 'danger'
            });
        }
    };

    const fetchEmployeeList = async (searchText) => {
        const res = await get('employeeProfile', { query: searchText }, null, true);
        console.log(res);

        if (res && res.status === 200) {
            const { data } = res;
            if (data.code === 1) {
                const roleList = data.data
                    .map((item) => ({ name: `${item.firstName} ${item.lastName}`, value: item.userId }));
                console.log(roleList);
                setEmployeeList(roleList);
            } else {
                notification({
                    title: 'Error getting List of Employees',
                    message: data.message,
                    type: 'danger'
                });
            }
        } else {
            notification({
                title: 'Error getting List of Employees',
                message: 'Something has gone wrong. Please, try again.',
                type: 'danger'
            });
        }
    };

    const handleChange = (e) => {

        if (e.target.name === "userId" || e.target.name === "accessTypeId") {
            setPayload({ ...payload, [e.target.name]: Number(e.target.value)});
        }
        else if (e.target.name === "hasAccess") {
            if (e.target.value === "true") {
                setPayload({ ...payload, [e.target.name]: true });
            }
            else {
                setPayload({ ...payload, [e.target.name]: false });
            }
        }
        if (e.target.name === "mda") {
            getEmployeeList(e.target.value);
        }
    }


    const submitForm = async () => {
        console.log(payload)
        // const body = {
        //     name,
        //     address,
        //     dateOfEstablishment,
        //     permanentSecretaryId,
        //     mandate
        // };
        // const error = validateForm(body);

        if (payload.userId === 0) {
            notification({
                title: 'Invalid Entry',
                message: "Please select a user",
                type: 'danger'
            });
            return;
        }
        else if (payload.accessTypeId === 0) {
            notification({
                title: 'Invalid Entry',
                message: "Please select user's access",
                type: 'danger'
            });
            return;
        }
        else if (payload.hasAccess === null) {
            notification({
                title: 'Invalid Entry',
                message: "Please select action on access",
                type: 'danger'
            });
            return;
        }


        // if (error) {
        //     notification({
        //         title: 'Invalid Entry',
        //         message: error,
        //         type: 'danger'
        //     });
        //     return;
        // }


        setIsLoading(true);
        const res = formType === 'create'
            ? await post('postAccess', payload, true) : await patch('mda', payload, details.id, true);
        console.log(res);
        setIsLoading(false);

        if (res && res.status === 200) {
            const { data } = res;
            notification({
                title: `Successful Access ${formType === 'create' ? 'Creation' : 'Update'}`,
                message: data.message,
                type: 'success'
            });
            // updateList(data.data, formType === 'edit');
            if (formType === 'edit') {
                closeModal();
                return;
            }
            setName('');
            setMandate('');
            setAddress('');
            setpermanentSecretaryId('');
            setDateOfEstablishment('');

        } else {
            notification({
                title: 'Network Error',
                message: `Something went wrong while ${formType === 'create' ? 'creating' : 'updating'} an MDA. Please, try again later.`,
                type: 'danger'
            });
        }
    };

    return (
        <div className="overlay">
            <IoMdClose className="close-btn pointer" onClick={closeModal} />
            <div className="modal-box max-w-400">
                <div className="content">
                    <SelectInput
                        name="mda"
                        // value={name}
                        options={mdaList || []}
                        onChange={handleChange}
                        className="w-100 m-b-10"
                        label="Select User's MDA*"
                    />
                    <SelectInput
                        name="userId"
                        // value={mandate}
                        options={employeeList || []}
                        onChange={handleChange}
                        className="w-100 m-b-10"
                        rows={3}
                        label="Select User*"
                    />
                    <SelectInput
                        name="accessTypeId"
                        // value={dateOfEstablishment}
                        onChange={handleChange}
                        options={accessList}
                        className="w-100 m-b-10"
                        label="Choose Access*"
                    />

                    <SelectInput
                        name="hasAccess"
                        // value={address}
                        options={accessRights}
                        onChange={handleChange}
                        className="w-100 m-b-10"
                        label="Manage Access*"
                    />
                    <button
                        type="button"
                        className="btn btn-large w-100"
                        onClick={submitForm}
                        disabled={isLoading}
                    >
                        {!isLoading && `${formType === 'create' ? 'Create' : 'Update'} Access`}
                        {isLoading && `${formType === 'create' ? 'Creating' : 'Updating'} Access...`}
                        {isLoading && <img src={Loader} className="btn-loading" alt="Loading..." />}
                    </button>
                </div>
            </div>
        </div>
    );
};

const validateForm = ({
    name, address, dateOfEstablishment, mandate, permanentSecretaryId
}) => {
    if (!name) return 'Please, enter a name for the MDA';
    if (!mandate) return 'Please, enter a mandate for the MDA';
    if (!dateOfEstablishment) return 'Please, enter date of Establishment for the MDA';
    // if (!permanentSecretaryId) return 'Please, choose the permanent Secretary for the MDA';
    if (getDaysFromDate(dateOfEstablishment) > 0) return 'Date of Establishment for the MDA must not be beyond today';
    if (!address) return 'Please, enter an address for the MDA';
    return null;
};

export default AccessForm;








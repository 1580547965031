/* eslint-disable implicit-arrow-linebreak */
import axios from 'axios';

export const getLocation = async (pos) =>
  fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${pos.lat},${pos.lng}&key=${process.env.REACT_APP_G_LOCATION_API_KEY}`
  )
    .then((response) => response.json())
    .then((data) => data);

export const getLocation2 = async (pos) => {
  const options = {
    url: 'http://18.225.15.178/services/api/userlocations',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: pos
  };
  console.log(options);
  return axios(options).then(
    (res) => res,
    (err) => err.response
  );
};

export const uploadPicture = (payload) => {
  const options = {
    url: `${process.env.REACT_APP_FILE_BASE_URL}services/api/documents/global`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Credentials': true,
      crossorigin: true
    },
    data: payload
  };
  console.log(options);
  return axios(options).then(
    (res) => res,
    (err) => err.response
  );
};

export const uploadSignature = (payload) => {
  const options = {
    url: `${process.env.REACT_APP_FILE_BASE_URL}services/api/documents/global`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Credentials': true,
      crossorigin: true
    },
    data: payload
  };
  console.log(options);
  return axios(options).then(
    (res) => res,
    (err) => err.response
  );
};

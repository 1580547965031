const maritalStatusList = [
  {
    name: 'Select item...',
    value: ''
  },
  {
    name: 'Married',
    value: 'Married'
  },
  {
    name: 'Single',
    value: 'Single'
  },
  {
    name: 'Divorced',
    value: 'Divorced'
  },
  {
    name: 'Widow',
    value: 'Widow'
  },
  {
    name: 'Widower',
    value: 'Widower'
  },
  {
    name: 'Undisclosed',
    value: 'Undisclosed'
  }
];

export default maritalStatusList;

/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import { RiAddLine } from 'react-icons/ri';
import { BiEditAlt } from 'react-icons/bi';
import { GiTrashCan } from 'react-icons/gi';

import IsLoading from '../common/IsLoading';
import SearchInput from '../inputs/SearchInput';
import LocationDetails from '../layouts/LocationDetails';
import OrganogramForm from '../layouts/OrganogramForm';
import notification from '../../utility/notification';
import { get, del } from '../../api-services/fetch';
import SelectInputColored from '../inputs/SelectInputColored';
import { formatDateTime } from '../../utility/dateTime';
import TablePaginationActions from '../common/TablePaginationActions';
import Greeting from '../common/Greeting';

const SupervisorOrganogram = ({ history }) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [details, setDetails] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [searchText, setSearchText] = useState('');
  const [mdas, setMdas] = useState([]);
  const [mdaFilter, setMda] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Supervisor Organogram';

    getList({});
    getMdas();
  }, []);

  const getList = async ({ query = null, mda = null }) => {
    console.log(query);
    setIsLoading(true);
    setErrorMsg('');
    const res = await get('organogram', { query, mda }, null, true);

    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        setDetails(data.data);
        if (data.data.length < 1) {
          if (query) {
            setErrorMsg('Organogram not found');
          } else {
            setErrorMsg('No Organogram yet');
          }
        }
      } else {
        setErrorMsg(data.message);
      }
    } else {
      setErrorMsg('Something has gone wrong. Please, try again.');
    }
    setIsLoading(false);
  };

  const getMdas = async () => {
    const res = await get('mda', null, null, true);
    console.log(res);
    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        const mdaList = data.data.map(({ name, id }) => ({ name, value: id }));
        mdaList.unshift({ name: 'All MDAs', value: '' });
        setMdas(mdaList);
      } else {
        notification({
          title: 'Error getting List of MDAs',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Error getting List of MDAs',
        message: 'Something has gone wrong. Please, try again.',
        type: 'danger'
      });
    }
  };

  const showEditForm = (id) => {
    const selected = details.filter((item) => id === item.id);

    if (selected.length > 0) {
      setSelectedRecord(selected[0]);
      setShowFormModal(true);
    }
  };

  const closeModal = () => {
    setSelectedRecord([]);
    setShowFormModal(false);
  };

  const deleteItem = async (id, name) => {
    const confirmAction = confirm(`Click OK to confirm the deletion of "${name}'s" Organogram`);
    if (!confirmAction) return;
    setIsDeleting(true);
    const res = await del('organogram', id, true);

    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        notification({
          title: 'Successful Organogram Deletion',
          message: data.message,
          type: 'success'
        });
        const others = details.filter((item) => id !== item.id);
        setDetails(others);
      } else {
        notification({
          title: 'Organogram Deletion Error',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Network Error',
        message: 'Something went wrong while deleting an Organogram. Please, try again later.',
        type: 'danger'
      });
    }
    setIsDeleting(false);
  };

  const handleSearch = async (e) => {
    const { name, value } = e.target;

    if (name === 'searchText') {
      setSearchText(value);
      console.log(value);
      await getList({ query: value, mda: mdaFilter });
    }
    if (name === 'mdaFilter') {
      setMda(value);
      await getList({ mda: value, query: searchText });
    }
  };

  const updateList = (newItem, isUpdate) => {
    if (isUpdate) {
      const newList = details.map((item) => (item.id === newItem.id ? newItem : item));
      setDetails(newList);
    } else {
      const newList = [...newItem, ...details];
      setDetails(newList);
    }
  };

  return (
    <div className="w-100">
      <div className="w-100 flex space-between">
      <Greeting />
        <LocationDetails />
      </div>
      <div className="w-100 flex space-between m-t-20 flex-v-center">
        <h2>Supervisory Organogram</h2>
        <div className="flex flex-v-center">
          <SelectInputColored
            className="m-l-5"
            name="mdaFilter"
            value={mdaFilter}
            onChange={handleSearch}
            label="Assign MDA"
            options={mdas || []}
          />
          <SearchInput
            name="searchText"
            value={searchText}
            onChange={handleSearch}
            className="m-l-5"
            placeholder="Search list..."
          />
        </div>
      </div>
      <div className="table-view m-t-10">
        <div className="table-header">
          <div className="table-row">
            <div className="th"><p>Staff List</p></div>
            <div className="th"><p>Supervisor 1</p></div>
            <div className="th"><p>Supervisor 2</p></div>
            <div className="th"><p>Last Updated By</p></div>
            <div className="th">
              <button className="add-button pointer" type="button" onClick={() => setShowFormModal(true)}>
                <RiAddLine />
                Add Profile
              </button>
            </div>
          </div>
        </div>
        <div className="table-body">
          {!(isLoading || errorMsg) && details && (rowsPerPage > 0
                ? details.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : details
              ).map((item) => (
            <div className="table-row" key={item.id}>
              <div className="td">{item.username}</div>
              <div className="td">{item.firstOfficerName}</div>
              <div className="td">{item.secondOfficerName}</div>
              <div className="td">
                {item.updatedBy || item.createdBy}
                <br />
                {formatDateTime(item.updatedAt || item.createdAt)}
              </div>
              <div className="td actions">
                <button type="button" className="pointer" onClick={() => showEditForm(item.id)}>
                  <BiEditAlt />
                </button>
                <button
                  type="button"
                  className="m-l-10 del pointer"
                  onClick={() => deleteItem(item.id, item.username)}
                >
                  <GiTrashCan />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {isLoading && <div className="w-100 center-text p-20">Loading...</div>}
      {errorMsg && <div className="w-100 center-text p-20">{errorMsg}</div>}
      {(isLoading || isDeleting) && <IsLoading message={isDeleting ? 'Deleting...' : null} />}
      {showFormModal && (
        <OrganogramForm details={selectedRecord} updateList={updateList} closeModal={closeModal} />
      )}
       <TablePaginationActions
            page={page}
            rowsPerPage={rowsPerPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            count={details.length}
            changeRowsPerPage={setRowsPerPage}
          />
    </div>
  );
};

export default SupervisorOrganogram;

/* eslint-disable operator-linebreak */
/* eslint-disable curly */
/* eslint-disable nonblock-statement-body-position */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { HiOutlinePhotograph } from 'react-icons/hi';
import { FaSignature } from 'react-icons/fa';
import { AiOutlineSearch } from 'react-icons/ai';
import ProfilePix from '../../assets/images/profile-pix.jpg';
import SignaturePix from '../../assets/images/signaturePlaceholder.webp';
import { get, patch } from '../../api-services/fetch';
import OfficialDetails from '../layouts/employeeDetailsForms/OfficialDetails';
import PersonalDetails from '../layouts/employeeDetailsForms/PersonalDetails';
import IsLoading from '../common/IsLoading';
import ViewProfile from '../layouts/ViewProfile';
import LocationDetails from '../layouts/LocationDetails';
import notification from '../../utility/notification';
import { getDaysFromDate } from '../../utility/dateTime';
import { uploadPicture, uploadSignature } from '../../api-services/otherApis';
import { formatFileUrl } from '../../utility/general';
import TextInput from '../inputs/TextInput';

const EmployeeDetails = ({ history }) => {
  const [currentForm, setCurrentForm] = useState('official-details');
  const [isSearching, setIsSearching] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [noValidRecord, setNoValidRecord] = useState(true);
  const [userId, setUserId] = useState('');
  const [toUploadPx, setToUploadPx] = useState(false);
  const [toUploadSx, setToUploadSx] = useState(false);
  const [active, setActive] = useState("Active")
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [email, setEmail] = useState('')
  const [staffId, setStaffId] = useState('');
  const [officialEmail, setOfficialEmail] = useState('');
  const [firstName, setFirstname] = useState('');
  const [dateOfFirstApp, setdateOfFirstApp] = useState('');
  const [lastName, setLastname] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [bvn, setBvn] = useState('');
  const [currentLevel, setCurrentLevel] = useState('');
  const [currentStep, setCurrentStep] = useState('');
  const [mda, setMda] = useState('');
  const [role, setRole] = useState('');
  const [roleName, setRoleName] = useState('');
  const [nationality, setNationality] = useState('');
  const [nin, setNin] = useState('');
  const [detailslink, setDetailslink] = useState('');
  const [gender, setGender] = useState('');
  const [maritalStatus, setMaritalStatus] = useState('');
  const [dob, setDob] = useState('');
  const [motherMaidenName, setMotherMaidenName] = useState('');
  const [placeOfBirth, setPlaceofBirth] = useState('');
  const [profilePicture, setProfilePicture] = useState('');
  const [signature, setSignature] = useState(null);
  const [religion, setReligion] = useState('');
  const [salaryAccountNumber, setSalaryAccountNumber] = useState('');
  const [salaryDomiciledBank, setSalaryDomiciledBank] = useState('');
  const [state, setState] = useState('');
  const [weddingAnniversary, setWeddingAnniversary] = useState('');
  const [title, setTitle] = useState('');
  const [rin, setRin] = useState('');
  const [pictureFile, setPictureFile] = useState('');
  const [pixUrl, setPixUrl] = useState('');
  const [signatureFile, setSignatureFile] = useState('');
  const [signatureUrl, setSignatureUrl] = useState('');
  const [salaryGrade, setSalaryGrade] = useState('');
  const [stepOfFirstApp, setStepOfFirstApp] = useState('');
  const [dateFirstAppointment, setDateFirstAppointment] = useState('');
  const [cadreFirstAppointment, setCadreDateFirstAppointment] = useState('');
  const [employeeNumber, setEmployeeNumber] = useState('');
  const [fileNumber, setFileNumber] = useState('');
  const [dateConfirmAppointment, setDateConfirmAppointment] = useState('');
  const [datePresentPositon, setDatePresentPosition] = useState('');
  const [mobile, setMobileNumber] = useState('');
  const [personalEmail, setPersonalEmail] = useState('');
  const [lGOrigin, setLGOrigin] = useState('');
  const [homeTown, setHomeTown] = useState('');
  const [permHomeAddress, setPermanentHomeAddress] = useState('');
  const [residentialAddress, setResisdentialAddress] = useState('');
  const [languageSpoken, setLanguageSpoken] = useState('');
  const [currentCadre, setcurrentCadre] = useState("");
  const [selected, setSelected] = useState("Not Selected");

  // const componentRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Employee Details';
    console.log(role,active,currentCadre )
  }, [selected,active,role,cadreFirstAppointment]);

  // useEffect(() => {
  //   !imgHasError && setUserPix(formatFileUrl(details.imagePath));
  // }, [imgHasError, details]);

  // const handleImgError = () => {
  //   setUserPix('');
  //   setImgHasError(true);
  // };

  const showProfileForm = () => {
    if (!staffId) return;

    if (staffId > 0) {
      setShowProfileModal(true);
    }
  };

  

  const closeProfileModal = () => {
    setShowProfileModal(false);
  };

  const searchEmployee = async () => {
    if (!staffId) return;

    setIsSearching(true);
    const res = await get('employeeProfile', null, staffId, true);
    console.log('Employee details', res);
    // console.log('Employee RoleName', res.data.data.role[0].name);
    setIsSearching(false);
    if (res && res.status === 200) {
      const { data } = res;
    console.log('Employee RoleName', data?.data?.role[0]?.name);

      if (data.code === 1) {
        notification({
          title: 'Successful Employee Details Retrieval',
          message: 'Employee details successfully retrieved. You can now review/update the record.',
          type: 'success'
        });
        setNoValidRecord(false);

        setUserId(data.data.userId);
        setOfficialEmail(data.data.officialEmail);
        setFirstname(data.data.firstName);
        setdateOfFirstApp(data.data.dateOfFirstApp)
        setLastname(data.data.lastName);
        setMiddleName(data.data.middleName);
        setCurrentLevel(data.data.currentLevel);
        setCurrentStep(data.data.currentStep);
        setMda(data.data.mda.id);
        setRole(data.data.role && data?.data?.role[0]?.id);
        setRoleName(data?.data?.role && data?.data?.role[0]?.name);
        setEmail(data?.data?.email)
        setBvn(data.data.bvn);
        setNin(data.data.nin);
        setSelected(data.data.isSelected);
        setCadreDateFirstAppointment(data.data.firstAppCadre);
        setDob(data.data.dob);
        setLanguageSpoken(data.data.languageSpoken);
        setPlaceofBirth(data.data.placeOfBirth);
        setDateFirstAppointment(data.data.dateOfFirstApp);
        setDatePresentPosition(data.data.dateOfPresentPosition);
        setNationality(data.data.nationality.id);
        setGender(data.data.gender);
        setActive(data?.data?.isStaff);
        setReligion(data.data.religion.id);
        setSalaryGrade(data.data.gradeOfFirstApp);
        setState(data.data.state.id);
        setResisdentialAddress(data.data.residentialAddress);
        setcurrentCadre(data.data.currentCadre);
        setStepOfFirstApp(data.data.stepOfFirstApp);
        setDateConfirmAppointment(data.data.dateOfConfirmation);
        setFileNumber(data.data.fileNumber);
        setPersonalEmail(data.data.email);
        setEmployeeNumber(data.data.employeeNumber);
        setWeddingAnniversary(data.data.weddingAnniversary);
        setTitle(data.data.title);
        setRin(data.data.rin);
        setSalaryDomiciledBank(data.data.salaryDomiciledBank);
        setSalaryAccountNumber(data.data.salaryAccountNumber);
        setMotherMaidenName(data.data.motherMaidenName);
        setMaritalStatus(data.data.maritalStatus);
        setProfilePicture(data.data.profilePicture);
        setSignature(data.data.signature);
        setPermanentHomeAddress(data.data.permHomeAddress);
        setHomeTown(data.data.homeTown);
        setMobileNumber(data.data.mobile);
        setDetailslink(
          `https://edogoverp.com/detailsform/index.html?emp=${data.data.staffId}&name=${data.data.firstName} ${data.data.lastName}`
        );
        data.data.signature && setSignatureUrl('Signature Exists');
      } else {
        notification({
          title: 'Employee Details Retrieval Error',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Network Error',
        message:
          'Something went wrong while retrieving an employee details. Please, try again later.',
        type: 'danger'
      });
    }
  };

  const validateEntries = () => {
    if (!staffId) return 'Please, enter Staff ID of the employee';
    if (!firstName) return 'Please, enter first name of the employee';
    if (firstName && !firstName.match(/^[A-Za-z\-]+$/)) {
      return 'First name should contain only alphabets';
    }
    if (!lastName) return 'Please, enter last name of the employee';
    if (lastName && !lastName.match(/^[A-Za-z\\-]+$/))
      return 'Last name/Surname should contain only alphabets';
    if (middleName && !middleName.match(/^[A-Za-z\\-]+$/))
      return 'Middle name should contain only alphabets';
    if (motherMaidenName && !motherMaidenName.match(/^[A-Za-z\\-]+$/))
      return 'Mother maiden name should contain only alphabets';
    if (
      officialEmail &&
      !/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(officialEmail)
    ) {
      return 'Please enter a valid email';
    }
    if (!mda) return 'Please, select an MDA for the employee';
    if (!role) return 'Please, select at least one Role for the employee';
    if (!rin) return 'Please, enter a valid RIN for the employee';
    if (!gender) return 'Please, select gender of the employee';
    if (nin && !nin.match(/^\d{11,}$/)) return 'Please enter a valid NIN';
    if (bvn && !bvn.match(/^\d{11,}$/)) return 'Please enter a valid BVN';
    if (salaryAccountNumber && !salaryAccountNumber.match(/^\d{10,}$/)) {
      return 'Please enter a valid Bank account number';
    }
    if (getDaysFromDate(dob) > -1) return 'Date of birth must not be beyond today';
    if (getDaysFromDate(weddingAnniversary) > -1) {
      return 'Wedding Anniversary must not be beyond today';
    }
    return null;
  };

  const updateRecord = async () => {
    const error = validateEntries();
    let ppix = profilePicture;
    let spix = signature;

    if (error) {
      notification({
        title: 'Employee Details Entry Error',
        message: error,
        type: 'danger'
      });
      return;
    }

    setIsUpdating(true);
    if (toUploadPx) {
      const form = new FormData();
      form.append('files', pictureFile);

      const res = await uploadPicture(form);

      console.log(res);
      if (res && res.status === 200) {
        const { data } = res;
        if (data.code === 1) {
          notification({
            title: 'Profile Picture Uploaded',
            message: 'The profile picture was uploaded successfully',
            type: 'success'
          });
          setProfilePicture(data.doclink);
          ppix = data.doclink;
        } else {
          notification({
            title: 'Profile Picture Upload Failed',
            message: data.message,
            type: 'danger'
          });
        }
      } else {
        notification({
          title: 'Network Error',
          message:
            'Something went wrong while upload the profile picture for this employee. Please, try again later.',
          type: 'danger'
        });
      }
    }
    console.log(profilePicture);

    if (toUploadSx) {
      const form = new FormData();
      form.append('files', signatureFile);

      const res = await uploadSignature(form);

      console.log(res);
      if (res && res.status === 200) {
        const { data } = res;
        if (data.code === 1) {
          notification({
            title: 'Signature Uploaded',
            message: 'The signature was uploaded successfully',
            type: 'success'
          });
          setSignature(data.doclink);
          spix = data.doclink;
        } else {
          notification({
            title: 'Signature Upload Failed',
            message: data.message,
            type: 'danger'
          });
        }
      } else {
        notification({
          title: 'Network Error',
          message:
            'Something went wrong while uploading the signature for this employee. Please, try again later.',
          type: 'danger'
        });
      }
    }
    console.log(profilePicture);

    const body = {
      userId,
      officialEmail,
      title,
      firstName,
      middleName,
      lastName,
      gender,
      currentLevel,
      salaryAccountNumber,
      salaryDomiciledBank,
      stepOfFirstApp,
      nin,
      role: [Number(role)],
      bvn,
      staffId,
      dob,
      isSelected:selected,
      placeOfBirth,
      maritalStatus,
      state: Number(state),
      nationality: Number(nationality),
      mda: Number(mda),
      religion: Number(religion),
      motherMaidenName,
      weddingAnniversary,
      profilePicture: ppix,
      signature: spix,
      mobile,
      homeTown,
      rin,
      email: personalEmail,
      permHomeAddress,
      residentialAddress,
      dateOfFirstApp: dateFirstAppointment,
      gradeofFirstApp: salaryGrade,
      firstAppCadre: cadreFirstAppointment?.toString(),
      fileNumber,
      dateOfConfirmation: dateConfirmAppointment,
      dateOfPresentPosition: datePresentPositon,
      currentCadre:currentCadre?.toString(),
      currentStep,
      languageSpoken,
      employeeNumber,
      isStaff:active
    };

    const res = await patch('employeeDetails', body, null, true);
    console.log(res);
    setIsUpdating(false);
    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        notification({
          title: 'Successful Employee Details Update',
          message: data.message,
          type: 'success'
        });
        // setSignatureUrl('signature Saved');
      } else {
        notification({
          title: 'Employee Details Update Error',
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Network Error',
        message:
          'Something went wrong while updating an employee details. Please, try again later.',
        type: 'danger'
      });
    }
  };

  const uploadProfilePix = () => {
    const uploadField = document.getElementById('image-upload');

    uploadField.click();
    uploadField.onchange = async () => {
      if (!uploadField.files[0]) return;
      const { type, size } = uploadField.files[0];
      const supportedTypes = ['jpeg', 'png', 'gif'];
      const fileType = type.slice(type.indexOf('/') + 1);
      if (size / 1024 > 500) {
        notification({
          title: 'File Too Large',
          message: 'The file size must not be more than 500KB',
          type: 'danger'
        });
        return;
      }
      if (!supportedTypes.includes(fileType)) {
        notification({
          title: 'Invalid File Type',
          message: 'Invalid file format. Supported file types are jpeg, png and gif.',
          type: 'danger'
        });
        return;
      }
      const imageUrl = uploadField.files[0];

      setPictureFile(uploadField.files[0]);
      const reader = new FileReader();

    reader.onloadend = () => {
      setPixUrl(reader.result);
    };

    reader.readAsDataURL(imageUrl);

      // setPixUrl(imageUrl);
      notification({
        title: 'Profile Picture Accepted',
        message: 'Profile picture is valid. "Update Form" to save the new profile picture',
        type: 'success'
      });
      setToUploadPx(true)
    };
  };

  const uploadSignaturePix = () => {
    const uploadField = document.getElementById('signature-upload');

    uploadField.click();
    uploadField.onchange = async () => {
      if (!uploadField.files[0]) return;
      const { type, size } = uploadField.files[0];
      const supportedTypes = ['jpeg', 'png'];
      const fileType = type.slice(type.indexOf('/') + 1);

      if (size / 1024 > 500) {
        notification({
          title: 'File Too Large',
          message: 'The file size must not be more than 500KB',
          type: 'danger'
        });
        return;
      }
      if (!supportedTypes.includes(fileType)) {
        notification({
          title: 'Invalid File Type',
          message: 'Invalid file format. Supported file types are jpeg and png.',
          type: 'danger'
        });
        return;
      }
      const imageUrl = URL.createObjectURL(uploadField.files[0]);
      console.log(imageUrl)

      setSignatureFile(uploadField.files[0]);
      setSignatureUrl(imageUrl);
      notification({
        title: 'Signature Accepted',
        message: 'Signature is valid. "Update Form" to save the new signature',
        type: 'success'
      });
      setToUploadSx(true)

    };
  };

  return (
    <div className="w-100 p-r">
      {isUpdating && <IsLoading message={isUpdating ? 'Updating Employee Record...' : null} />}
      {isSearching && <IsLoading message={isSearching ? 'Searching Employee Record...' : null} />}
      {showProfileModal && <ViewProfile staffId={staffId} closeModal={closeProfileModal} />}
      {/* {noValidRecord && (
        <div className="staffid-form-overlay">
          <div className="inner-box content">
            <FormSearchInput
              className="w-100 m-b-20"
              label="Enter Employee Staff ID"
              value={staffId}
              name="staffId"
              onChange={(e) => setStaffId(e.target.value)}
            />
            <button
              type="button"
              className="btn btn-large w-100"
              disabled={isSearching}
              onClick={searchEmployee}>
              Search for Employee
              {isSearching && <img src={Loader} className="btn-loading" alt="Loading..." />}
            </button>
          </div>
        </div>
      )} */}
      <div className="w-100 flex space-between flex-v-center">
        <h2>Staff Onboarding</h2>
        <LocationDetails />
      </div>
      <div className="w-100 flex space-between flex-v-center m-t-10">
        <div className="col-6 no-margin">
          <div className="flex flex-h-center flex-v-center">
            <TextInput
              className="w-100 m-b-10"
              label="Staff ID/ Email/NIN"
              value={staffId || ''}
              onChange={(e) => {
                setNoValidRecord(true);
                setStaffId(e.target.value);
              }}
            />
            <div className="m-t-auto m-b-10">
              <button className="p-10 pointer" type="button" onClick={searchEmployee}>
                <AiOutlineSearch color="rgba(120,120,120,.7)" fontSize="18px" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 m-t-20 user-full-details">
        <div className="tab-btns w-100">
          <button
            type="button"
            onClick={() => setCurrentForm('official-details')}
            className={currentForm === 'official-details' ? 'active' : ''}>
            Official Details
          </button>
          <button
            type="button"
            onClick={() => setCurrentForm('personal')}
            className={currentForm === 'personal' ? 'active' : ''}
            disabled={noValidRecord}>
            Personal
          </button>
          {!noValidRecord && (
            <Link
              to={{ pathname: `${detailslink}` }}
              // href={`${detailslink}`}
              target="_blank"
              rel="noreferrer">
              {console.log('logging details link', detailslink)}
              View More
            </Link>
          )}
          {/* <button
            type="button"
            onClick={() => setCurrentForm('dependent')}
            className={currentForm === 'dependent' ? 'active' : ''}
          >
            Dependant
          </button>
          <button
            type="button"
            onClick={() => setCurrentForm('education')}
            className={currentForm === 'education' ? 'active' : ''}
          >
            Education
          </button>
          <button
            type="button"
            onClick={() => setCurrentForm('profession')}
            className={currentForm === 'profession' ? 'active' : ''}
          >
            Profession
          </button>
          <button
            type="button"
            onClick={() => setCurrentForm('training')}
            className={currentForm === 'training' ? 'active' : ''}
          >
            Training
          </button>
          <button
            type="button"
            onClick={() => setCurrentForm('work-experience')}
            className={currentForm === 'work-experience' ? 'active' : ''}
          >
            Work Experience
          </button>
          <button
            type="button"
            onClick={() => setCurrentForm('referee')}
            className={currentForm === 'referee' ? 'active' : ''}
          >
            Referee/Guarantor
          </button>
          <button
            type="button"
            onClick={() => setCurrentForm('privileges')}
            className={currentForm === 'privileges' ? 'active' : ''}
          >
            Access/Privileges
          </button> */}
        </div>
        {!noValidRecord && (
          <div className="w-100 flex">
            <div className="left">
              <div className="content w-100">
                {currentForm === 'official-details' && (
                  <OfficialDetails
                    staffId={{ value: staffId, onChange: (e) => setStaffId(e.target.value) }}
                    officialEmail={{
                      value: officialEmail,
                      onChange: (e) => setOfficialEmail(e.target.value)
                    }}
                    currentLevel={{
                      value: currentLevel,
                      onChange: (e) => setCurrentLevel(e.target.value)
                    }}
                   
                    mda={{ value: mda, onChange: (value) => setMda(value) }}
                    role={{ value: role,  onChange: (value, name) => {setRoleName(name.name); setRole(value)} }}
                    bvn={{ value: bvn, onChange: (e) => setBvn(e.target.value) }}
                    nin={{ value: nin, onChange: (e) => setNin(e.target.value) }}
                    salaryAccountNumber={{
                      value: salaryAccountNumber,
                      onChange: (e) => setSalaryAccountNumber(e.target.value)
                    }}
                    salaryDomiciledBank={{
                      value: salaryDomiciledBank,
                      onChange: (e) => setSalaryDomiciledBank(e.target.value)
                    }}
                    selected={{
                      value: selected,
                      onChange: (e) => setSelected(e.target.value)
                    }}
                    roleName = {roleName}
                    active={{
                      value: active,
                      onChange: (e) => setActive(e.target.value)
                    }}
                    salaryGrade={{
                      value: salaryGrade,
                      onChange: (e) => setSalaryGrade(e.target.value)
                    }}
                    stepOfFirstApp={{
                      value: stepOfFirstApp,
                      onChange: (e) => setStepOfFirstApp(e.target.value)
                    }}
                    currentStep={{
                      value: currentStep,
                      onChange: (e) => setCurrentStep(e.target.value)
                    }}
                    currentCadre={{
                      value: currentCadre,
                      onChange: (name) => setcurrentCadre(name)
                    }}
                    dateFirstAppointment={{
                      value: dateFirstAppointment,
                      onChange: (e) => setDateFirstAppointment(e.target.value)
                    }}
                    cadreFirstAppointment={{
                      value: cadreFirstAppointment,
                      onChange: (name) => setCadreDateFirstAppointment(name)
                    }}
                    employeeNumber={{
                      value: employeeNumber,
                      onChange: (e) => setEmployeeNumber(e.target.value)
                    }}
                    fileNumber={{
                      value: fileNumber,
                      onChange: (e) => setFileNumber(e.target.value)
                    }}
                    dateConfirmAppointment={{
                      value: dateConfirmAppointment,
                      onChange: (e) => setDateConfirmAppointment(e.target.value)
                    }}
                    datePresentPosition={{
                      value: datePresentPositon,
                      onChange: (e) => setDatePresentPosition(e.target.value)
                    }}
                    searchEmployee={searchEmployee}
                    noValidRecord={noValidRecord}
                  />
                )}
                {currentForm === 'personal' && (
                  <PersonalDetails
                    staffId={{ value: staffId, onChange: (e) => setStaffId(e.target.value) }}
                    title={{ value: title, onChange: (e) => setTitle(e.target.value) }}
                    lastName={{ value: lastName, onChange: (e) => setLastname(e.target.value) }}
                    firstName={{ value: firstName, onChange: (e) => setFirstname(e.target.value) }}
                    middleName={{
                      value: middleName,
                      onChange: (e) => setMiddleName(e.target.value)
                    }}
                    dob={{ value: dob, onChange: (e) => setDob(e.target.value) }}
                    nationality={{
                      value: nationality,
                      onChange: (e) => setNationality(e.target.value)
                    }}
                    placeOfBirth={{
                      value: placeOfBirth,
                      onChange: (e) => setPlaceofBirth(e.target.value)
                    }}
                    gender={{ value: gender, onChange: (e) => setGender(e.target.value) }}
                    religion={{ value: religion, onChange: (e) => setReligion(e.target.value) }}
                    maritalStatus={{
                      value: maritalStatus,
                      onChange: (e) => setMaritalStatus(e.target.value)
                    }}
                    state={{ value: state, onChange: (e) => setState(e.target.value) }}
                    motherMaidenName={{
                      value: motherMaidenName,
                      onChange: (e) => setMotherMaidenName(e.target.value)
                    }}
                    weddingAnniversary={{
                      value: weddingAnniversary,
                      onChange: (e) => setWeddingAnniversary(e.target.value)
                    }}
                    mobile={{
                      value: mobile,
                      onChange: (e) => setMobileNumber(e.target.value)
                    }}
                    personalEmail={{
                      value: personalEmail,
                      onChange: (e) => setPersonalEmail(e.target.value)
                    }}

                    rin={{
                      value: rin,
                      onChange: (e) => setRin(e.target.value)
                    }}

                    lGOrigin={{
                      value: lGOrigin,
                      onChange: (e) => setLGOrigin(e.target.value)
                    }}
                    homeTown={{
                      value: homeTown,
                      onChange: (e) => setHomeTown(e.target.value)
                    }}
                    permHomeAddress={{
                      value: permHomeAddress,
                      onChange: (e) => setPermanentHomeAddress(e.target.value)
                    }}
                    residentialAddress={{
                      value: residentialAddress,
                      onChange: (e) => setResisdentialAddress(e.target.value)
                    }}
                    languageSpoken={{
                      value: languageSpoken,
                      onChange: (e) => setLanguageSpoken(e.target.value)
                    }}
                  />
                )}
                {!noValidRecord && (
                  <div className="w-100 flex m-t-10">
                    <div className="col-half margin-center">
                      <button
                        type="button"
                        className="btn btn-light w-100 btn-large"
                        onClick={updateRecord}
                        disabled={isUpdating}>
                        Update Form
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="right">
              <div className="w-100 m-t-40 center-text profile-brief">
                <img
                  src={pixUrl || profilePicture || ProfilePix}
                  alt="Profile Pic."
                />
                <img
                  src={signature || signatureUrl || SignaturePix}
                  alt="Signature Pic."
                  style={{ borderRadius: 0, height: '100px', width: '100px' }}
                />
                {/* </div> */}
                <div className="brief m-t-20">
                  <h4>{`${firstName || ''} ${lastName || ''}`}</h4>
                  <hr />
                  <p className="m-b-10">
                    <b>Engagement Date:{dateOfFirstApp || ' August 1, 2019'}</b>
                   
                  </p>
                  <p className="m-b-10">
                    <b>Duration on Grade:&nbsp;</b>
                    30 months
                  </p>
                </div>
                <button
                  type="button"
                  onClick={uploadProfilePix}
                  className="btn btn-round flex margin-center m-t-10">
                  {profilePicture || pixUrl ? 'Change Picture' : 'Upload Picture'}
                  <HiOutlinePhotograph className="right" />
                  <input type="file" hidden id="image-upload" />
                </button>
                {console.log('logging Signature', signature, signatureUrl)}
                {(!signature || !signatureUrl) && (
                  <button
                    type="button"
                    onClick={uploadSignaturePix}
                    className="btn btn-round flex margin-center m-t-10">
                    {/* {profilePicture || pixUrl ? 'Change Signature' : 'Upload Signature'} */}
                    Upload Signature
                    <FaSignature className="right" />
                    <input type="file" hidden id="signature-upload" />
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeeDetails;

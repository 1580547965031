/* eslint-disable indent */
/* eslint-disable object-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-closing-bracket-location */
import React, { useState, useEffect } from 'react';
import Loader from '../../assets/svg/loading.svg';
import notification from '../../utility/notification';
import { toggleScroll } from '../../utility/general';
import { decodeToken } from '../../utility/auth';

// Api
import { put, post, get } from '../../api-services/fetch';

// Inputs
import SelectInput from '../inputs/SelectInput';

const ModulePartRoleForm = ({ details, reload, getDetails, setReload }) => {
  const [modules, setModules] = useState([]);
  const [module, setModule] = useState(0);
  const [moduleParts, setModuleParts] = useState([]);
  const [modulePart, setModulePart] = useState(0);
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState(0);
  const [formType, setFormType] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    toggleScroll();
    if (details && details.id) {
      setFormType('edit');
      // setModuleParts(currentModuleParts || []);
      setModule(details.moduleId || 0);
      setModulePart(details.modulePartId || '');
      setRole(details.role || 0);
    } else {
      setFormType('create');
    }

    return () =>
      setTimeout(() => {
        toggleScroll();
      }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  useEffect(() => {
    getModules({});
    getRoles({});

    // module && getModuleParts({}, module);
  }, []);

  useEffect(() => {
    getModuleParts({}, module);
  }, [module]);

  const getModules = async ({ query = null }) => {
    const res = await get('module', { query }, null, true);

    console.log('Logging Get category Res', res);
    if (res && res.status === 200) {
      const { data } = res;
      if (data.length > 0) {
        const modulesList = data.map((item) => ({ name: item.urlName, value: item.id }));
        modulesList.unshift({ name: 'Select item...', value: '' });
        setModules(modulesList);
      }
    }
  };
  const getModuleParts = async ({ query = null }, moduleId) => {
    const res = await get('modulePart', { query }, moduleId, true);

    console.log('Logging Get category Res', res);
    if (res && res.status === 200) {
      const { data } = res;
      if (data.length > 0) {
        const modulesList = data.map((item) => ({ name: item.modulePartName, value: item.id }));
        modulesList.unshift({ name: 'Select item...', value: '' });
        setModuleParts(modulesList);
      } else {
        setModuleParts([]);
        setModulePart(0);
      }
    }
  };
  const getRoles = async ({ query = null }) => {
    const res = await get('moduleRoles', { query }, null, true);

    console.log('Logging Get category Res', res);
    if (res && res.status === 200) {
      const { data } = res;
      if (data.length > 0) {
        const moduleRolesList = data.map((item) => ({ name: item.name, value: item.id }));
        moduleRolesList.unshift({ name: 'Select item...', value: '' });
        setRoles(moduleRolesList);
      }
    }
  };

  const submitForm = async () => {
    let body;
    formType === 'edit' && details.id
      ? (body = {
          moduleId: module,
          modulePartId: modulePart,
          role,
          userId: decodeToken('id').id,
          id: details.id
        })
      : (body = {
          moduleId: module,
          modulePartId: modulePart,
          role,
          userId: decodeToken('id').id
        });

    console.log('logging Body', body);
    const error = validateForm(body);

    if (error) {
      notification({
        title: 'Invalid Entry',
        message: error,
        type: 'danger'
      });
      return;
    }

    setIsLoading(true);
    // eslint-disable-next-line operator-linebreak
    const res =
      formType === 'create'
        ? await post('modulePartRole', body, true)
        : await put('modulePartRole', body, details.id, true);
    console.log(res);
    setIsLoading(false);

    if (res && res.status === 200) {
      const { data } = res;
      if (data && (data.message === 'Record saved' || data.message === 'Record updated')) {
        notification({
          title: `Successful Module Part ${formType === 'create' ? 'Creation' : 'Update'}`,
          message: data.message,
          type: 'success'
        });
        // update Table
        setReload(!reload);
        // updateList(data, formType === 'edit');
        // if (formType === 'edit') {
        //   closeModal();
        //   return;
        // }
        setModule('');
        setModulePart('');
        setRole('');
      } else {
        notification({
          title: `Module Part ${formType === 'create' ? 'Creation' : 'Update'} Error`,
          message: data.message,
          type: 'danger'
        });
      }
    } else {
      notification({
        title: 'Network Error',
        message: `Something went wrong while ${
          formType === 'create' ? 'creating' : 'updating'
        } an Module Part. Please, try again later.`,
        type: 'danger'
      });
    }
  };

  return (
    <div
    // className="overlay"
    >
      {/* <IoMdClose className="close-btn pointer" onClick={closeModal} /> */}
      <div className="modal-box max-w-400">
        <div className="content">
          <SelectInput
            name="module"
            options={modules || []}
            value={module}
            onChange={(e) => {
              setModule(Number(e.target.value));
              e.target.value && getModuleParts({}, e.target.value);
            }}
            className="w-100 m-b-10"
            label="Module*"
          />
          <SelectInput
            name="modulePart"
            options={moduleParts || []}
            value={modulePart}
            onChange={(e) => {
              setModulePart(Number(e.target.value));
              e.target.value && getDetails({}, e.target.value);
            }}
            className="w-100 m-b-10"
            label="Module Part*"
          />
          <SelectInput
            name="role"
            options={roles || []}
            value={role}
            onChange={(e) => setRole(Number(e.target.value))}
            className="w-100 m-b-10"
            label="Role*"
          />
          <button
            type="button"
            className="btn btn-large w-100"
            onClick={submitForm}
            disabled={isLoading}>
            {!isLoading && `${formType === 'create' ? 'Save' : 'Update'} Module Part Role`}
            {isLoading && `${formType === 'create' ? 'Saving' : 'Updating'} Module Part Role...`}
            {isLoading && <img src={Loader} className="btn-loading" alt="Loading..." />}
          </button>
        </div>
      </div>
    </div>
  );
};

const validateForm = ({ moduleId, modulePartId, role }) => {
  if (!moduleId) return 'Please, select a Module';
  if (!modulePartId) return 'Please, select a Module Part';
  if (!role) return 'Please, select a Role';
  return null;
};

export default ModulePartRoleForm;

import React, { useEffect, useState } from 'react';

import LocationIcon from '../../assets/images/location-icon-100.png';
import { decodeToken } from '../../utility/auth';
import { formatTime } from '../../utility/dateTime';

const LocationDetails = () => {
  const [geoLocate, setGeoLocate] = useState('');
  const [lastLogin, setLastLogin] = useState('');

  useEffect(() => {
    const gl = localStorage.getItem('gl');
    const lo = localStorage.getItem('lo');
    gl && setGeoLocate(decodeToken('gl').gl);
    lo && setLastLogin(decodeToken('lo').lo);
  }, []);

  return (
    <div className="location-details flex flex-v-center">
      <div className="m-r-5 right-text" style={{ paddingTop: '3px' }}>
        <p className="location">{geoLocate || 'Unknown Location'}</p>
        <p className="login-time">
          Last Logged in:&nbsp;
          {formatTime(lastLogin) || 'N/A'}
        </p>
      </div>

    </div>
  );
};

export default LocationDetails;
